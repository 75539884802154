import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiRoot, googleTTSsupportedLanguages } from '../config'
import { enqueueAudio, isDesktop, pronounce_builtin } from '../Utilities/tools'

// Define the initial state
interface PronounceState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: PronounceState = {
  status: 'idle',
  error: null,
}

interface PronounceProps {
  text: string;
  language: string;
  slow?: boolean;
}

// Define the async thunk
export const pronounce = createAsyncThunk(
  'pronounce/pronounce',
  async ({ text, language, slow = false }: PronounceProps) => {
    if (!googleTTSsupportedLanguages.includes(language)) {
      if (isDesktop()) return { text, language, slow }
      throw new Error('Pronunciation not supported for Esperanto on mobile devices.')
    }
    const params = new URLSearchParams({ language, text, speed: slow ? 'slow' : 'normal'})
    const response = await axios.get(apiRoot + `/api/pronunciation/?${params.toString()}`)
    return response.data
  })

// Define the slice
const pronounceSlice = createSlice({
  name: 'pronounce',
  initialState: initialState as PronounceState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(pronounce.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(pronounce.fulfilled, (state, action) => {
        state.status = 'succeeded'
        if (!action.payload.audio) {
          pronounce_builtin(action.payload.text, action.payload.language, action.payload.slow)
          return
        }
        enqueueAudio(action.payload.audio)
      })
      .addCase(pronounce.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || ''
      })
  },
})

// Export the actions and the reducer
export default pronounceSlice.reducer