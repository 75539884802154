import { createTheme } from '@mui/material/styles'

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#2a97ce',  // same as var(--primary-color)
    },
    secondary: {
      main: '#777',  // same as var(--secondary-color)
    },
  },
})

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2a97ce',  // same as var(--primary-color)
    },
    secondary: {
      main: '#888',  // same as var(--secondary-color)
    },
  },
})

export { lightTheme, darkTheme }
