const translations = {
  translations: {
    Required: 'Dieses Feld ist erforderlich',
    'Page Title': 'Vokabular',
    'Login Title': 'Anmelden',
    'Register Title': 'Registrieren',
    Username: 'Nutzername',
    Password: 'Passwort',
    'E-mail': 'E-Mail',
    Name: 'Name',
    Login: 'Anmelden',
    Register: 'Registrieren',
    RegisterError: 'Bei der Registrierung ist ein Fehler aufgetreten.',
    RegistrationSuccess: 'Registrierung erfolgreich',
    InvalidEmail: 'E-Mail-Adresse ist ungültig',
    UserAlreadyExists: 'Der Benutzer existiert bereits',
    SignUp: 'Anmelden',

    Profile: 'Profil',
    Logout: 'Abmelden',
    LogoutSuccess: 'Sie wurden abgemeldet',
    'Change password': 'Passwort ändern',
    'New Password': 'Neues Passwort',
    PasswordRequired: 'Passwort ist erforderlich',
    ConfirmNewPassword: 'Neues Passwort bestätigen',
    ConfirmPasswordRequired: 'Bestätigung des Passworts ist erforderlich',
    PasswordsDontMatch: 'Die Passwörter stimmen nicht überein',
    PasswordSuccess: 'Passwort erfolgreich geändert',
    PasswordUpdateError: 'Fehler beim Ändern des Passworts',
    SaveChanges: 'Änderungen speichern',
    'Site language': 'Seitensprache',
    'Preferred Language': 'Bevorzugte Sprache',
    LanguageSaved: 'Spracheinstellungen erfolgreich aktualisiert',
    LanguageSaveFailed: 'Fehler beim Aktualisieren der Spracheinstellungen',
    Save: 'Speichern',

    'tooltip.Search': 'Suche',
    'tooltip.Add': 'Zeile hinzufügen',
    'tooltip.Delete': 'Zeile löschen',
    'tooltip.Learn': 'Wörter lernen',
    'tooltip.More': 'Mehr Aktionen',
    'tooltip.Share': 'Vokabular teilen',
    'tooltip.FlipRow': 'Zeile umdrehen',
    ShowHidePronunciations: 'Aussprachen umschalten',
    ShowHideCopyButtons: 'Kopiertasten umschalten',
    VocabularyTitlePlaceholder: 'Vokabeltitel eingeben',

    'search.placeholder': 'Wort oder Phrase übersetzen',
    ErrorLoadingPreferences:
      'Benutzereinstellungen konnten nicht geladen werden',
    ErrorCreatingVocabulary: 'Vokabular konnte nicht erstellt werden',
    ErrorLoadingVocabularies: 'Vokabulare konnten nicht geladen werden',

    SavingVocabulary: 'Änderungen am Vokabular werden gespeichert...',
    VocabularySaved: 'Vokabularänderungen erfolgreich gespeichert',

    AddNewVocabulary: 'Neues Vokabular hinzufügen',
    UntitledVocabulary: '(Ohne Titel)',
    Search: 'Suche',
    ErrorLoadingPage: 'Fehler beim Laden der Seite. Bitte aktualisieren.',
    DeleteVocabulary: 'Vokabular löschen',
    VocabularyDeleted: 'Vokabular gelöscht',
    ErrorDeletingVocabulary: 'Fehler beim Löschen des Vokabulars',

    ShareVocabulary: 'Vokabular teilen',
    CopyLink: 'Link kopieren',
    OK: 'OK',
    LinkCopiedToClipboard: 'Link in die Zwischenablage kopiert',

    setup: 'Einrichten',
    direction: 'Richtung',
    leftToRight: 'Von links nach rechts',
    rightToLeft: 'Von rechts nach links',
    pronunciation: 'Aussprache',
    on: 'An',
    off: 'Aus',
    skipSetup: 'Einrichtung beim nächsten Mal überspringen',
    play: 'Spielen',

    'cards.splashTitle': 'Flash-Karten aus deinem Vokabular',
    'cards.splashText1': 'Lerne Wörter in deinem Vokabular mit Flash-Karten. <p>Zuerst kriegst du kleinere Blöcke, so wie dieses:</p>',
    'cards.splashText2': 'Dann immer größere Blöcke:',
    'cards.skipDemo': 'Demo nächstes Mal überspringen.',
    'cards.seeDemo': 'Die Demo gucken',
    'cards.letsStart': 'Flash-Karten starten',
    'cards.demo': 'Demo',
    'cards.demoTitle': 'Demo',
    'cards.step1': 'Schritt 1:',
    'cards.demoText1': 'Du siehst einer fremdsprachige Karte. Sag aus seine Bedeutung!',
    'cards.back': 'Zurück',
    'cards.next': 'Weiter',
    'cards.step2': 'Schritt 2:',
    'cards.demoText2s1': 'Drücke ',
    'cards.demoText2s2': ', zu bestätigen, dass du es geschafft hast.',
    'cards.step3': 'Schritt 3:',
    'cards.demoText3s1': 'Jetzt, falls du denkst dass deine Antwort gut war, drücke ',
    'cards.demoText3s2': '.<br> falls du denkst, dass es falsch war, drücke ',
    'cards.demoText3s3': '.',
    'cards.step4': 'Schritt 4:',
    'cards.demoText4': 'Nächste Karte!<br>Du bekommst immer neuere Karten, bis du alle Karten gelernt hast.<br>Du kannst deine Fortschritt unten im Fenster überprüfen.',

    'cards.finishedTitle': 'Gratuliere! Du hast es geschafft!',
    'cards.backToHome': 'Zurück zum Vokabular',

    'play.Setup': 'Einrichten',
    'play.Demo': 'Wie funktioniert es?',
    'errorSavingPreferences': 'Fehler beim Speichern der Einstellungen.',

    'cardShow.loading': 'Laden...',
    'cardShow.errorGettingVocabularyId': 'Die Kartendaten konnten nicht geladen werden. Bitte aktualisieren.',
    'cardShow.errorStartingGame': 'Das Spiel konnte nicht gestartet werden. Bitte aktualisieren.',
    'cardShow.errorGettingNextCard': 'Fehler beim Abrufen der nächsten Karte, bitte sende deine Antwort erneut.',

    'restart': 'Neustart',
    'continue': 'Weiter',
    'CopiedToClipboard': 'Text in die Zwischenablage kopiert',
    'cardShow.errorSavingRow': 'Fehler beim Speichern der Zeile',

    'definitions': 'Definitionen',
    'PronunciationFailed': 'Das Wort konnte nicht ausgesprochen werden.',

    '404.title': '404 Nicht gefunden',
    '404.text': 'Die Seite, die Sie suchen, existiert nicht.',
    '404.back': 'Zurück zur Startseite',

    'BackToYourVocab': 'Zurück zu deinem Vokabular',

    NumbersGame: 'Zahlenspiel',
    ChineseCharactersGame: 'Chinesische Zeichenspiel',
    SelectLanguage: 'Sprache auswählen',
    ShowPinyin: 'Pinyin anzeigen',
    EnablePronunciation: 'Aussprache aktivieren',

    Reload: 'Neu laden',
    Cancel: 'Abbrechen',

    SessionExpired: 'Deine Sitzung ist abgelaufen. Bitte melde dich erneut an.',
    ErrorLoadingVocabulary: 'Fehler beim Laden des Wortschatzes',

    WarningPermanentAction: 'Diese Aktion ist dauerhaft! Bist du sicher?',
    SplitVocabulary: 'Vokabular an der Auswahllinie aufteilen',
    VocabularySplitInfo: 'Das Vokabular wurde erfolgreich geteilt. Du kanst die anderen Zeilen unter "{{title}}" finden.',
    ErrorSplittingVocabulary: 'Fehler beim Teilen des Vokabulars',
  },
}
export default translations
