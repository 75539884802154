// This is the normal way:
//const envFile = process.env.NODE_ENV === 'production' ? '.env.development.production' : '.env.development.development';
//require('dotenv').config({ path: envFile });
//export const apiRoot = `${process.env.BACKEND_HOST}/api/proxy`

// for now a more simple solution:

import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'

export const isVirtual = async () => {
  const info = await Device.getInfo()
  console.log('isVirtual', info.isVirtual)
  return info.isVirtual
}

const platform = Capacitor.getPlatform()

// This will be true if the app is running on an iOS device
const isIOS = platform === 'ios'
const isAndroid = platform === 'android'
export const isApp = isIOS || isAndroid
const isProduction = process.env.NODE_ENV === 'production'
// Use the isIOS variable in your configuration

const myLocalIp = isProduction? '127.0.0.1' : process.env['REACT_APP_MY_LOCAL_IP'] || '127.0.0.1'
console.log('myLocalIp', myLocalIp)
export let server = (
  isApp ?
    (isProduction ? 'https://vocabulary.translatian.com' : `http://${myLocalIp}:8001`) :
    (isProduction ? '' :
      (window.location.hostname==='translatian.test') ? 'https://translatian.test': `http://${myLocalIp}:8001`
    )
)
export let apiRoot = (
  (server.includes('localhost') || server.includes(myLocalIp)) ?
    `${server}` :
    `${server}/api/proxy`
);
(async () => {
  if (isProduction || !isApp) return
  const isVirtualDevice = await isVirtual()
  if (isVirtualDevice) return
  server = `http://${myLocalIp}:8001`
  apiRoot = `${server}`
})()


export const languageNames: Record<string, string> = {
  ar: 'Arabic',
  bg: 'Bulgarian',
  cs: 'Czech',
  de: 'German',
  en: 'English',
  eo: 'Esperanto',
  es: 'Spanish',
  fi: 'Finnish',
  fr: 'French',
  hr: 'Croatian',
  hu: 'Hungarian',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  nl: 'Dutch',
  pt: 'Portuguese',
  ro: 'Romanian',
  ru: 'Russian',
  sk: 'Slovak',
  sr: 'Serbian',
  sv: 'Swedish',
  zh: 'Chinese',
}

export const googleTTSsupportedLanguages = [
  'af', 'ar', 'eu', 'bn', 'bg', 'ca', 'yue', 'cs', 'da', 'nl', 'en', 'fi', 'fr', 'gl', 'de', 'el', 'gu', 'he', 'hi', 'hu', 'is', 'id', 'it', 'ja', 'kn', 'ko', 'ms', 'ml', 'cmn', 'mr', 'nb', 'pl', 'pt', 'pa', 'ro', 'ru', 'sr', 'sk', 'es', 'sv', 'ta', 'te', 'th', 'tr', 'uk', 'vi', 'zh',
]