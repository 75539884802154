// typing ref: https://redux.js.org/usage/usage-with-typescript

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiRoot } from '../config'
import { clearPreferences, getUserPreferences } from './PreferencesSlice'

export interface LoginPayload {
  username: string
  password: string
}

function updateAxiosToken() {
  const token = localStorage.getItem('token')
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Token ${token}`
  }
}

updateAxiosToken()

export const login = createAsyncThunk(
  'auth/login',
  async ({ username, password }: LoginPayload, { dispatch }) => {
    let response
    try {
      response = await axios.post(apiRoot + '/auth/token/login/', {
        username,
        password,
      })
    } catch (error) {
      await dispatch(logOut())
      throw error
    }
    localStorage.setItem('token', response.data.auth_token)
    updateAxiosToken()
    await dispatch(fetchUser())
    await dispatch(getUserPreferences())
    return response.data
  },
)

export const logOut = createAsyncThunk('auth/logOut', async (_, { dispatch }) => {
  localStorage.removeItem('token')
  dispatch(clearPreferences())
  updateAxiosToken()
  return null
})

export const fetchUser = createAsyncThunk('auth/fetchUser', async () => {
  if (!localStorage.getItem('token')) throw new Error('No token')
  try {
    const response = await axios.get(apiRoot + '/auth/users/me/')
    return response.data
  } catch (err: any) {
    if (err.response && err.response.status === 401) {
      throw new Error('Unauthorized')
    }
    throw err
  }
})

export interface RegisterPayload {
  username: string
  email: string
  password: string
}

export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async (
    { username, email, password }: RegisterPayload,
    { dispatch, rejectWithValue },
  ) => {
    let result
    try {
      const response = await axios.post(apiRoot + '/auth/users/', {
        username,
        email,
        password,
      })
      result = response.data.data
    } catch (err: any) {
      // Handle the error response from axios
      if (!err.response) {
        console.log('err:', err)
        return rejectWithValue({ message: 'Could not register.' })
      }
      // if 5xx error, we can't do anything, so we just return the error
      if (err.response.status >= 500) {
        return rejectWithValue({ message: 'Server error' })
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(err.response.data)
    }
    const loginResult = await dispatch(login({ username, password }))
    if (!login.fulfilled.match(loginResult)) {
      return rejectWithValue({ message: 'Could not login.' })
    }
    return result
  },
)

interface User {
  id: number
  username: string
  email: string
}

export interface AuthState {
  user: User | null
  preferences: any | null
  isLoggedIn: boolean
  loading: boolean
  errorMessage: string
}

function getInitialState(): AuthState {
  const token = localStorage.getItem('token')
  return {
    user: null,
    preferences: null,
    isLoggedIn: !!token,
    loading: false,
    errorMessage: '',
  }
}

const initialState: AuthState = getInitialState()

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false
      localStorage.removeItem('token')
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.user = null
        state.isLoggedIn = false
        state.loading = true
        state.errorMessage = ''
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.loading = false
        state.errorMessage = ''
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false
        state.user = null
        const payload: any = action.payload || {}
        if (
          'username' in payload &&
          payload.username[0] === 'A user with that username already exists.'
        ) {
          state.errorMessage = 'UserAlreadyExists'
        } else if (payload.message) {
          state.errorMessage = payload.message
        } else {
          // @ts-ignore
          state.errorMessage = action.error.message
        }
      })
      .addCase(login.pending, (state) => {
        state.loading = true
        state.errorMessage = ''
      })
      .addCase(login.fulfilled, (state) => {
        state.loading = false
        state.isLoggedIn = true
        state.errorMessage = ''
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false
        state.user = null
        if (action.error.code === 'ERR_BAD_REQUEST') {
          state.errorMessage = 'Invalid credentials'
        } else {
          // @ts-ignore
          state.errorMessage = action.error.message
        }
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(logOut.fulfilled, (state) => {
        state.isLoggedIn = false
        state.user = null
      })
  },
})

export default authSlice.reducer
