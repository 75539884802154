import { Chip } from '@mui/material'
import React, { useMemo } from 'react'
import { useAppSelector } from '../hooks'
import { OverridableStringUnion } from '@mui/types'
import { ChipPropsColorOverrides } from '@mui/material/Chip/Chip'

const CurrentVocabularyLineCount: React.FC = () => {
  const currentVocabulary = useAppSelector(
    (state) => state.vocabularies.currentVocabulary,
  )

  const vocabularyRowCounter = useMemo<number>(() => {
    return currentVocabulary?.rows.filter(row=>row.left || row.right).length || 0
  }, [currentVocabulary])

  const vocabularyCounterColor = useMemo<
    | undefined
    | OverridableStringUnion<
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning',
    ChipPropsColorOverrides
  >
  >(() => {
    if (vocabularyRowCounter < 10) {
      return 'default'
    } else if (vocabularyRowCounter < 20) {
      return 'primary'
    } else if (vocabularyRowCounter < 30) {
      return 'warning'
    } else if (vocabularyRowCounter < 50) {
      return undefined
    } else {
      return 'error'
    }
  }, [vocabularyRowCounter])

  return (
    <Chip
      label={vocabularyRowCounter}
      color={vocabularyCounterColor}
      sx={{ minWidth: 40, opacity: 0.9 }}
    />
  )
}

export default CurrentVocabularyLineCount


