/*
Use ref.getCurrentRow() to get the current row data.
Vocabulary reports all changes to the parent component via the onChange prop.
(it reports that happened -- cell edit or drag and drop reordering of the rows)
Vocabulary does not change its data directly, it only reports changes to the parent component.
 */

import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import './Vocabulary.less'
import { DragDropContext, Draggable, DraggableProvided, DroppableProvided, DropResult } from 'react-beautiful-dnd'
import StrictModeDroppable from './StrictModeDroppable'

import { IVocabulary, IVocabularyRow } from '../Store/VocabulariesSlice'
import VocabularyTools, { DisplayTool } from './VocabularyTools'
import { sanitizeCell } from '../Utilities/tools'

interface VocabularyProps {
  vocabulary: IVocabulary
  displayTools?: DisplayTool
  onChange?: (data: any) => void,
  readOnly?: boolean
}

export interface VocabularyHandle {
  getCurrentRow: () => { index: number; row: IVocabularyRow }
  getCurrentCell: () => { rowIndex: number; column: 'left' | 'right' }
}

const Vocabulary = forwardRef(
  ({ vocabulary, displayTools = '', onChange = () => {}, readOnly = false }: VocabularyProps, ref) => {

    const [data, setData] = useState(vocabulary.rows)
    useEffect(() => {
      setData(vocabulary.rows)
    }, [vocabulary.rows])

    const [lastFocusedRow, setLastFocusedRow] = useState<number>(0)
    const [lastFocusedColumn, setLastFocusedColumn] = useState<'left' | 'right'>('left')
    const handleFocus = (index: number, column: 'left' | 'right') => {
      handleLastRowAdd(index)
      setLastFocusedRow(index)
      setLastFocusedColumn(column)
    }
    const handleCellEdit = (
      rowIndex: number,
      column: 'left' | 'right',
      newValue: string,
    ) => {
      const formattedValue = newValue.replace(/\/\//g, '<br>')
      if (newValue === data[rowIndex][column]) return
      if (!onChange) {
        const newData: IVocabularyRow[] = [...data]
        newData[rowIndex][column] = formattedValue
        setData(newData)
        return
      }
      const row = { ...data[rowIndex] }
      row[column] = formattedValue
      onChange({ operation: 'updateRow', position: rowIndex, row, column })
    }

    const handleLastRowAdd = (rowIndex: number) => {
      if (rowIndex === data.length - 1) {
        if (!onChange) {
          // Add a new blank row to the data array
          setData((prevData) => [
            ...prevData,
            {
              id: 0,
              left: '',
              right: '',
              left_score: 0,
              right_score: 0,
            },
          ])
          return
        }
        onChange({ operation: 'addRow', position: data.length - 1 })
      }
    }

    const handleDragEnd = (result: DropResult) => {
      if (!result.destination) return
      const items = Array.from(data)
      const [reorderedItem] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedItem)
      if (!onChange) {
        setData(items)
        return
      }
      onChange({ operation: 'rearrangeRows', rowIds: items.map((row) => row.id) })
    }
    const getCurrentRow = (rowIndex?: number) => {
      rowIndex = rowIndex || lastFocusedRow
      return { index: rowIndex, row: data[rowIndex] }
    }
    const getCurrentCell = () => {
      return { rowIndex: lastFocusedRow, column: lastFocusedColumn }
    }
    useImperativeHandle(ref, () => ({
      getCurrentRow, getCurrentCell,
    }))

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, index: number, columnClass: string) => {
      // Handle arrow up and down keys
      if (['ArrowUp', 'ArrowDown', 'Enter'].includes(e.key)) {
        e.preventDefault()
        const table = e.currentTarget.parentNode?.parentNode as HTMLElement
        const divs = table.querySelectorAll(`.td.${columnClass}`) as unknown as HTMLElement[]
        if ((e.key === 'ArrowUp' || (e.key === 'Enter' && e.shiftKey)) && index > 0) {
          divs[index - 1].focus()
        } else if (['ArrowDown', 'Enter'].includes(e.key) && index < divs.length - 1) {
          divs[index + 1].focus()
        }
      }
      if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
        const caretPos = window.getSelection()?.getRangeAt(0).startOffset
        const textLength = e.currentTarget.textContent?.length
        const table = e.currentTarget.parentNode?.parentNode as HTMLElement
        const divs = table.querySelectorAll('.td.input') as unknown as HTMLElement[]
        const inputIndex = (columnClass === 'source') ? 2 * index : 2 * index + 1
        if (e.key === 'ArrowLeft' && caretPos === 0 && inputIndex > 0) {
          const prevDiv = divs[inputIndex - 1]
          prevDiv.focus()
          // Place the caret after the last letter
          const l = prevDiv.textContent?.length || 0
          if (l) {
            const range = document.createRange()
            const sel = window.getSelection()
            range.setStart(prevDiv, 1)
            range.collapse(true)
            sel?.removeAllRanges()
            sel?.addRange(range)
          }
        } else if (['ArrowRight'].includes(e.key) && caretPos === textLength && inputIndex < divs.length - 1) {
          e.preventDefault()
          divs[inputIndex + 1].focus()
        }
      }
    }

    return (
      <div className={`Vocabulary ${displayTools ? 'show-tools' : ''}`}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <StrictModeDroppable droppableId="table">
            {(provided: DroppableProvided) => (
              <div
                className="table"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {data.map((row, index) => (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                  >
                    {(provided: DraggableProvided, snapshot) => (
                      <div
                        className={`tr ${index === lastFocusedRow ? 'last-selected' : ''} ${snapshot.isDragging ? 'ui-dragging' : ''}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div
                          className="td selector"
                          {...provided.dragHandleProps}
                          tabIndex={-1}
                        >
                          <div className="grab"></div>
                        </div>
                        <div
                          className="td input source"
                          lang={vocabulary.left_language}
                          contentEditable={!readOnly}
                          suppressContentEditableWarning
                          onFocus={() => {
                            handleFocus(index, 'left')
                          }}
                          onBlur={(e) =>
                            handleCellEdit(
                              index,
                              'left',
                              sanitizeCell(e.target.innerHTML),
                            )
                          }
                          onKeyDown={(e) => {
                            handleKeyDown(e, index, 'source')
                          }}
                          dangerouslySetInnerHTML={{
                            __html: sanitizeCell(row.left),
                          }}
                        />
                        <VocabularyTools
                          value={row.left}
                          language={vocabulary.left_language}
                          displayTools={displayTools}
                        />
                        <div
                          className="td input target"
                          lang={vocabulary.right_language}
                          contentEditable={!readOnly}
                          suppressContentEditableWarning
                          onFocus={() => {
                            handleFocus(index, 'right')
                          }}
                          onBlur={(e) =>
                            handleCellEdit(
                              index,
                              'right',
                              sanitizeCell(e.target.innerHTML),
                            )
                          }
                          onKeyDown={(e) => {
                            handleKeyDown(e, index, 'target')
                          }}
                          dangerouslySetInnerHTML={{
                            __html: sanitizeCell(row.right),
                          }}
                        />
                        <VocabularyTools
                          value={row.right}
                          language={vocabulary.right_language}
                          displayTools={displayTools}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </div>
    )
  },
)

export default Vocabulary
