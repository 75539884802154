const translations = {
  translations: {
    Required: 'This field is required',
    'Page Title': 'Vocabulary',
    'Login Title': 'Login',
    'Register Title': 'Sign up with a free account',
    Username: 'Username',
    Password: 'Password',
    'E-mail': 'E-mail',
    Name: 'Name',
    Login: 'Login',
    Register: 'Register',
    RegisterError: 'An error occurred during registration.',
    RegistrationSuccess: 'Registration successful',
    InvalidEmail: 'Email address is invalid',
    UserAlreadyExists: 'User already exists',
    SignUp: 'Sign Up',

    Profile: 'Profile',
    Logout: 'Logout',
    LogoutSuccess: 'You have been logged out',
    'Change password': 'Change password',
    'New Password': 'New Password',
    PasswordRequired: 'PasswordRequired',
    ConfirmNewPassword: 'Confirm new password',
    ConfirmPasswordRequired: 'ConfirmPasswordRequired',
    PasswordsDontMatch: 'Passwords don\'t match',
    PasswordSuccess: 'Password changed successfully',
    PasswordUpdateError: 'Error updating password',
    SaveChanges: 'Save changes',
    'Site language': 'Site language',
    'Preferred Language': 'Preferred Language',
    LanguageSaved: 'Language preferences updated successfully',
    LanguageSaveFailed: 'Error updating language preferences',
    Save: 'Save',

    SwitchLanguages: 'Switch Languages',

    'tooltip.Search': 'Search',
    'tooltip.Add': 'Add Row',
    'tooltip.Delete': 'Delete Row',
    'tooltip.Learn': 'Learn Words',
    'tooltip.More': 'More actions',
    'tooltip.Share': 'Share Vocabulary',
    'tooltip.FlipRow': 'Flip Row',
    ShowHidePronunciations: 'Toggle pronunciations',
    ShowHideCopyButtons: 'Toggle copy buttons',
    VocabularyTitlePlaceholder: 'Enter Vocabulary Title',

    'search.placeholder': 'Translate word or phrase',
    ErrorLoadingPreferences: 'Could not load user preferences',
    ErrorCreatingVocabulary: 'Could not create vocabulary',
    ErrorLoadingVocabularies: 'Could not load vocabularies',

    SavingVocabulary: 'Saving changes vocabulary is in progress...',
    VocabularySaved: 'Vocabulary changes saved successfully',

    AddNewVocabulary: 'Add New Vocabulary',
    UntitledVocabulary: '(Untitled)',
    Search: 'Search',
    ErrorLoadingPage: 'Error loading page. Please refresh.',
    DeleteVocabulary: 'Delete Vocabulary',
    VocabularyDeleted: 'Vocabulary deleted',
    ErrorDeletingVocabulary: 'Error deleting vocabulary',

    ShareVocabulary: 'Share Vocabulary',
    CopyLink: 'Copy Link',
    OK: 'OK',
    LinkCopiedToClipboard: 'Link copied to clipboard',

    setup: 'Setup',
    direction: 'Direction',
    leftToRight: 'Left to Right',
    rightToLeft: 'Right to Left',
    pronunciation: 'Pronunciation',
    on: 'On',
    off: 'Off',
    skipSetup: 'Skip setup next time',
    play: 'Play',

    'cards.splashTitle': 'Play flash cards from your vocabulary',
    'cards.splashText1':
      'Memorize words in your vocab using flash cards. <p>At first you will learn smaller blocks, like this:</p>',
    'cards.splashText2': 'Then you will learn bigger and bigger blocks:',
    'cards.skipDemo': 'Skip demo next time.',
    'cards.seeDemo': 'let’s see the demo!',
    'cards.letsStart': 'let\'s start!',
    'cards.demo': 'demo',
    'cards.demoTitle': 'Demo',
    'cards.step1': 'Step 1:',
    'cards.demoText1':
      'You can see a word on a card. Now say out loud its meaning!',
    'cards.back': 'back',
    'cards.next': 'next',
    'cards.step2': 'Step 2:',
    'cards.demoText2s1': 'Press ',
    'cards.demoText2s2': ' to confirm if you were right.',
    'cards.step3': 'Step 3:',
    'cards.demoText3s1': 'Now, if you were correct, press ',
    'cards.demoText3s2': '.<br> If you think that were wrong, press ',
    'cards.demoText3s3': '.',
    'cards.step4': 'Step 4:',
    'cards.demoText4':
      'Next card!<br>You get newer and newer cards until you\'ve learned them all.<br>You can check your progress at the bottom of the window.',

    'cards.finishedTitle': 'Congratulations, you have finished!',
    'cards.backToHome': 'Back to the vocabulary',

    'play.Setup': 'Setup',
    'play.Demo': 'How does it work?',
    errorSavingPreferences: 'Error saving preferences.',

    'cardShow.loading': 'Loading...',
    'cardShow.errorGettingVocabularyId':
      'Could not load the card data. Please refresh.',
    'cardShow.errorStartingGame': 'Could not start the game. Please refresh.',
    'cardShow.errorGettingNextCard':
      'Error getting next card, please submit your answer again.',

    restart: 'Restart',
    continue: 'Continue',
    CopiedToClipboard: 'Copied text to clipboard',
    'cardShow.errorSavingRow': 'Error saving row',

    'definitions': 'Definitions',
    PronunciationFailed: 'Could not pronounce the word.',

    '404.title': '404 Not found',
    '404.text': 'The page you are looking for does not exist.',
    '404.back': 'Back to the home page',

    'BackToYourVocab': 'Back to your vocabulary',

    NumbersGame: 'Numbers Game',
    ChineseCharactersGame: 'Chinese Characters Game',
    SelectLanguage: 'Select Language',
    ShowPinyin: 'Show Pinyin',
    EnablePronunciation: 'Enable Pronunciation',

    Reload: 'Reload',
    Cancel: 'Cancel',

    SessionExpired: 'Your session has expired. Please log in again.',
    ErrorLoadingVocabulary: 'Could not load vocabulary',

    WarningPermanentAction: 'This action is permanent! Are you sure?',
    SplitVocabulary: 'Split vocabulary at the selection line',
    VocabularySplitInfo: 'Vocabulary was split successfully. You can find the other rows in "{{title}}".',
    ErrorSplittingVocabulary: 'Error when splitting vocabulary',
  },
}
export default translations
