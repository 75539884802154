import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../hooks'
import { login } from '../Store/AuthSlice'
// import { apiRoot, isApp, isVirtual } from '../config'

function LoginPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { register, handleSubmit, watch } = useForm()
  const username = watch('username')
  const password = watch('password')
  const dispatch = useAppDispatch()
  const loading = useAppSelector((state) => state.auth.loading)
  const errorMessage = useAppSelector((state) => state.auth.errorMessage)

  const handleLogin = async () => {
    const loginResult = await dispatch(login({ username, password }))
    if (login.fulfilled.match(loginResult)) {
      navigate('/')
    }
  }

  const handleRegister = () => {
    // Redirect to register page
    navigate('/register')
  }

  return (
    <Container maxWidth="xs">
      <Box
        component="form"
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(handleLogin)}
      >
        <h2>{t('Login')}</h2>
        {/*{apiRoot} {isApp ? 'is' : 'is not'} an app*/}
        <TextField
          {...register('username', {
            required: t('Required'),
          })}
          required
          label={t('Username')}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          {...register('password', { required: t('Required') })}
          required
          label={t('Password')}
          variant="outlined"
          type="password"
          fullWidth
          margin="normal"
        />
        <Grid container spacing={2} justifyContent="center" marginTop={2}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleLogin} data-testid="login">
              {t('Login')}
              {loading && <CircularProgress size={24} />}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleRegister} data-testid="register">
              {t('Register')}
            </Button>
          </Grid>
        </Grid>
        {errorMessage && (
          <Grid container spacing={2} justifyContent="center" marginTop={2}>
            <Box sx={{ color: 'error.main' }}>{errorMessage}</Box>
          </Grid>
        )}
      </Box>
    </Container>
  )
}

export default LoginPage
