import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
} from '@mui/material'
import { toast } from 'react-toastify'
import { FieldError, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../hooks'
import { RegisterPayload, registerUser } from '../Store/AuthSlice'

function RegisterPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const username: string = watch('username')
  const email: string = watch('email')
  const password: string = watch('password')
  const dispatch = useAppDispatch()
  const { errorMessage, loading } = useAppSelector((state) => state.auth)

  const handleSignUp = async () => {
    const action = await dispatch(
      registerUser({ username, email, password } as RegisterPayload),
    )
    if (registerUser.fulfilled.match(action)) {
      toast.success(t('RegisterSuccess'))
      navigate('/')
    } else {
      toast.error(t('RegisterError'))
    }
  }

  const handleLogin = () => {
    // Redirect to login page
    navigate('/login')
  }

  return (
    <Container maxWidth="xs">
      <Box
        component="form"
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(handleSignUp)}
      >
        <h2>{t('Register')}</h2>
        <TextField
          {...register('username', { required: t('Required') })}
          required
          label={t('Username')}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        {errors.name && (
          <Box sx={{ color: 'error.main' }}>
            {(errors.name as FieldError).message}
          </Box>
        )}
        <TextField
          {...register('email', {
            required: t('Required'),
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: t('InvalidEmail'),
            },
          })}
          required
          label={t('E-mail')}
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
        />
        {errors.email && (
          <Box sx={{ color: 'error.main' }} data-testid="email-error">
            {(errors.email as FieldError).message}
          </Box>
        )}
        <TextField
          {...register('password', { required: t('Required') })}
          required
          label={t('Password')}
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
        />
        {errors.password && (
          <Box sx={{ color: 'error.main' }}>
            {(errors.password as FieldError).message}
          </Box>
        )}
        <Grid container spacing={2} justifyContent="center" marginTop={2}>
          <Grid item>
            <Button variant="contained" type="submit" data-testid="register">
              {t('SignUp')}
              {loading && <CircularProgress size={24} />}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleLogin} data-testid="login">
              {t('Login')}
            </Button>
          </Grid>
        </Grid>
        {errorMessage && (
          <Grid container spacing={2} justifyContent="center" marginTop={2}>
            <Box sx={{ color: 'error.main' }}>{errorMessage}</Box>
          </Grid>
        )}
      </Box>
    </Container>
  )
}

export default RegisterPage
