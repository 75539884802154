import React, { useMemo } from 'react'
import { Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ArrowForward } from '@mui/icons-material'

import './SearchTabs.less'
import { setActiveTab } from '../Store/SearchSlice'
import { useAppDispatch, useAppSelector } from '../hooks'
import { hasDefinitions } from '../Utilities/queryLinks'


const SearchTabs = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const activeTab = useAppSelector(state => state.search.activeTab)
  const source = useAppSelector(state => state.search.currentSearch?.source || '')
  const target = useAppSelector(state => state.search.currentSearch?.target || '')


  const showDefinitions = useMemo(() => hasDefinitions(source), [source])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    dispatch(setActiveTab(newValue))
  }

  return (
    <>{source &&
      <Tabs value={activeTab} onChange={handleChange} className="search-tabs">
        <Tab label={<>{source}<ArrowForward />{target}</>} value="dictionaries" />
        {showDefinitions && <Tab label={t('definitions')} value="definitions" />}
      </Tabs>
    }
    </>
  )
}

export default SearchTabs