import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiRoot } from '../config'
import { RootState } from '.'
import { AuthState, fetchUser } from './AuthSlice' // Import the AuthState type
import i18n from 'i18next'

export const getUserPreferences = createAsyncThunk(
  'auth/getUserPreferences',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState
    console.log('getUserPreferences', state.preferences.preferences)
    if (state.preferences.preferencesLoaded) return
    let id = state.auth.user?.id
    if (!id) {
      const result = await dispatch(fetchUser())
      if (fetchUser.rejected.match(result)) {
        if (result.error.message === 'Unauthorized') {
          throw new Error('Unauthorized')
        }
      }
      id = (getState() as RootState).auth.user?.id
    }
    if (!id) throw new Error('No user id')
    const response = await axios.get(apiRoot + `/api/preference/${id}/`)
    return response.data
  },
)

export const updatePreferences = createAsyncThunk(
  'auth/updatePreferences',
  async (newPreferences: IPreferences, { getState }) => {
    const state = getState() as RootState
    const existingPreferences = state.preferences.preferences

    // Check if there are differences between the new and existing preferences
    const hasDifferences = Object.keys(newPreferences).some(key => {
      // @ts-ignore
      return newPreferences[key] !== existingPreferences?.[key]
    })
    if (!hasDifferences) return

    const { user } = state.auth as AuthState
    const id = user?.id
    const response = await axios.patch(apiRoot + `/api/preference/${id}/`, newPreferences)
    return response.data
  },
)

export type Slide = 'demo' | 'setup' | 'play' | 'finished' | 'error'

interface IPreferences {
  cardStartSlide?: Slide
  cardDirection?: 'ltr' | 'rtl'
  cardPronunciations?: { [key: string]: boolean }
  cardShowPinyin?: boolean
  currentVocabulary?: number
  language?: string
  lastGameLanguage?: string
}

interface PreferencesState {
  preferences: IPreferences | null
  preferencesLoaded: boolean
}

const preferencesSlice = createSlice({
  name: 'preferences',
  initialState: {
    preferences: null,
    preferencesLoaded: false,
  } as PreferencesState,
  reducers: {
    clearPreferences: (state) => {
      state.preferences = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPreferences.fulfilled, (state, action) => {
        if (state.preferencesLoaded) return
        state.preferencesLoaded = true
        state.preferences = action.payload.data
        const myLanguage = state.preferences?.language || 'en'
        if (i18n.language !== myLanguage) i18n.changeLanguage(myLanguage)
      })
      .addCase(updatePreferences.fulfilled, (state, action) => {
        if (undefined === action.payload) return
        state.preferences = action.payload.data
        const myLanguage = state.preferences?.language || 'en'
        if (i18n.language !== myLanguage) i18n.changeLanguage(myLanguage)
      })
  },
})

export const { clearPreferences } = preferencesSlice.actions

export default preferencesSlice.reducer
