import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { ArrowForward, SwapHoriz } from '@mui/icons-material'
import LanguageDropdown from './LanguageDropdown'
import { useTranslation } from 'react-i18next'

import './LanguageSourceTarget.less'

interface LanguagePair {
  source: string;
  target: string;
}

interface LanguageSourceTargetProps {
  sourceLanguage: string;
  targetLanguage: string;
  onChange: (pair: LanguagePair) => void;
}

const LanguageSourceTarget: React.FC<LanguageSourceTargetProps> = (
  { sourceLanguage, targetLanguage, onChange },
) => {
  const { t } = useTranslation()
  const [isHovered, setIsHovered] = React.useState(false)

  const handleSwitch = () => {
    onChange({ source: targetLanguage, target: sourceLanguage })
  }

  function handleSelect({ source, target }: { source?: string, target?: string }) {
    if (source) {
      onChange({ source, target: (source === targetLanguage) ? sourceLanguage : targetLanguage })
    }
    if (target) {
      onChange({ target, source: (target === sourceLanguage) ? targetLanguage : sourceLanguage })
    }
  }

  return (
    <div>
      <LanguageDropdown
        className="source-dropdown"
        value={sourceLanguage}
        onChange={e => handleSelect({ source: e.target.value })}
        displayFullNames={false}
      />
      <Tooltip title={t('SwitchLanguages')}>
        <IconButton
          className="switch-button"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleSwitch}
        >
          {isHovered ? <SwapHoriz fontSize="small" /> : <ArrowForward fontSize="small" />}
        </IconButton>
      </Tooltip>
      <LanguageDropdown
        className="target-dropdown"
        value={targetLanguage}
        onChange={e => handleSelect({ target: e.target.value })}
        displayFullNames={false}
      />
    </div>
  )
}

export default LanguageSourceTarget
