// TODO: this whole file is just a Copilot-generated stub...

import { useAppSelector } from '../hooks'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Link } from '@mui/material'
import DOMPurify from 'dompurify'

import './SearchResults.less'
import { useMemo } from 'react'
import { OpenInNew } from '@mui/icons-material'

const SearchResults = () => {

  const { t } = useTranslation()

  const results = useAppSelector((state) => state.search.results)
  const activeTab = useAppSelector(state => state.search.activeTab)

  const displayedResults = useMemo(() => {
    return results.filter(result => result.extractor.category === activeTab)
  }, [results, activeTab])

  return (
    <div>
      {displayedResults.map((result, index) => (
        <div key={index} className="search-results">
          {(result.extractor.linkOnly || (!['succeeded', 'failed'].includes(result.response.status))) &&
            <div className="extractor">
              {result.extractor.title &&
                <Link href={result.extractor.link} target="_blank" rel="noopener noreferrer">
                  {result.extractor.title}
                  {result.extractor.linkOnly && <OpenInNew fontSize="small" sx={{ margin: '0 0 -4px 4px' }} />}
                </Link>
              }
              {!result.extractor.title &&
                t('Results from your vocabularies')
              }
            </div>}
          {result.response.status === 'loading' &&
            <CircularProgress size={24} />
          }
          {result.response.text &&
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(result.response.text) }} />
          }
          {result.response.statusText || ''}
        </div>
      ))}
    </div>
  )
}

export default SearchResults
