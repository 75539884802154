import { languageNames } from '../config'

export type Extractor = {
  link: string;
  title: string;
  name: string;
  category: 'dictionaries' | 'definitions';
  linkOnly?: boolean;
};

export const hasDefinitions = (source: string): boolean => {
  return true
}

export function getQueryLinks(source: string, target: string, query: string): Extractor[] {
  const dic: Record<string, string> = {
    hu: 'hun',
    en: 'eng',
    de: 'ger',
    it: 'ita',
    fr: 'fre',
    pl: 'pol',
    bg: 'bul',
    zh: 'cmn',
  }

  const getSztakiQuery = (name: string, query: string, source: string, target: string): string => {
    return 'https://szotar.sztaki.hu/search?viewMode=full&langprefix=&u=0&searchWord=' + query +
      '&ignoreAccents=1&langcode=' + source +
      '&fromlang=' + dic[source] +
      '&tolang=' + dic[target] +
      '&searchMode=WORD_PREFIX'
  }

  const getGoogleQuery = (query: string, source: string, target: string): string => {
    return 'https://translate.google.com/#' + source + '/' + target + '/' + query.replace(/\//g, '%2F')
  }

  const getGlosbeQuery = (query: string, source: string, target: string): string => {
    return 'https://glosbe.com/' + source + '/' + target + '/' + query
  }

  const extractors: Extractor[] = [
    { name: 'self', title: '', link: '', category: 'dictionaries' },
  ]

  if ((source === 'hu' && dic[target]) || (target === 'hu' && dic[source])) {
    extractors.push({
      name: 'sztaki',
      title: 'Sztaki Szótár',
      link: getSztakiQuery('sztaki', query, source, target),
      category: 'dictionaries',
    })
  }
  if ((source === 'es' && target === 'en') || (target === 'en' && source === 'es')) {
    extractors.push({
      name: 'spanishdict',
      title: 'SpanishDict',
      link: `https://www.spanishdict.com/translate/${query}`,
      category: 'dictionaries',
      linkOnly: true,
    })
  }

  extractors.push(
    {
      name: 'google-translate',
      title: 'Google Translate',
      link: getGoogleQuery(query, source, target),
      category: 'dictionaries',
      linkOnly: true,
    },
    {
      name: 'glosbe',
      title: 'Glosbe',
      link: getGlosbeQuery(query, source, target),
      category: 'dictionaries',
      linkOnly: true,
    },
  )

  const definitions = []

  if (source === 'de') {
    definitions.push({
      name: 'duden',
      title: 'Duden.de',
      link: 'https://www.duden.de/suchen/dudenonline/' + query,
    })
  } else if (source === 'en') {
    definitions.push({
      name: 'merriam-webster',
      title: 'Merriam-Webster',
      link: 'https://www.merriam-webster.com/dictionary/' + query,
    })
  } else if (source === 'eo') {
    definitions.push({
      name: 'vortaro',
      title: 'Vortaro.net',
      link: `https://www.vortaro.net/#${query}_kd`,
    })
  } else if (source === 'es') {
    // definitions.push({
    //   name: 'conjugacion-es',
    //   title: 'Conjugación.es',
    //   link: `https://www.conjugacion.es/del/verbo/${query}.php`,
    // })
    definitions.push({
      name: 'rae',
      title: 'Real Academia Española',
      link: `https://dle.rae.es/${query}`,
    })
    definitions.push({
      name: 'spanishdict',
      title: 'SpanishDict - conjugation',
      link: `https://www.spanishdict.com/conjugate/${query}`,
    })
  } else if (source === 'fr') {
    definitions.push({
      name: 'lerobert',
      title: 'Le Robert - Dico en ligne',
      link: `https://dictionnaire.lerobert.com/definition/${query}`,
    })
    definitions.push({
      name: 'lefigaro',
      title: 'Le Figaro Conjugaison',
      link: `https://leconjugueur.lefigaro.fr/french/verb/${query}.html`,
    })
  } else if (source === 'hu') {
    definitions.push({
      name: 'szojelentese',
      title: 'SzoJelentese',
      link: 'https://' + query + '.szojelentese.com',
      linkOnly: false,
    })
  } else if (source === 'it') {
    definitions.push({
      name: 'coniugazione-it',
      title: 'Coniugazione.it',
      link: `https://www.coniugazione.it/verbo/${query}.php`,
    })
    definitions.push({
      name: 'treccani',
      title: 'Treccani.it',
      link: `https://www.treccani.it/enciclopedia/ricerca/${query}/?search=${query}`,
    })
  } else if (source === 'pt') {
    definitions.push({
      name: 'conjugacao-pt',
      title: 'Conjugação.pt',
      link: `https://www.conjugacao.com.br/verbo-${query}/`,
    })
    definitions.push({
      name: 'coniugazione-pt',
      title: 'Coniugazione.it - portuguese',
      link: `https://www.coniugazione.it/portoghese/verbo/${query}.php`,
    })
  }
  definitions.push({
    name: `wiktionary-${source}`,
    title: `Wiktionary - ${source}`,
    link: `https://${source}.wiktionary.org/wiki/` + query,
  })

  if (source !== 'en') {
    const langname = languageNames[source] || ''
    definitions.push({
      name: `wiktionary-en`,
      title: `Wiktionary (general)`,
      link: `https://en.wiktionary.org/wiki/${query}#${langname}`,
    })
  }

  return extractors.concat(definitions.map(d => (
    { category: 'definitions', linkOnly: true, ...d }
  )))
}
