import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiRoot } from '../config'
import axios from 'axios'
import { updatePreferences } from './PreferencesSlice'
import { AuthState } from './AuthSlice'
import i18n from 'i18next'

export const updateRow = createAsyncThunk(
  'vocabularies/updateRow',
  async (row: Partial<IVocabularyRow>) => {
    const response = await axios.put(apiRoot + `/api/row/${row.id}/`, row)
    return response.data
  },
)

export const listMyVocabularies = createAsyncThunk(
  'vocabularies/listVocabularies',
  async (_) => {
    const response = await axios.get(apiRoot + '/api/vocabulary/')
    return response.data
  },
)

export const setCurrentVocabulary = createAsyncThunk(
  'vocabularies/setCurrentVocabulary',
  async (id: number, { dispatch, getState }) => {
    const state = getState() as { vocabularies: VocabulariesState, preferences: { preferences: any } }
    if (id === state.vocabularies.currentVocabulary?.id) return state.vocabularies.currentVocabulary
    const response = await axios.get(apiRoot + `/api/vocabulary/${id}/`)
    if (state.preferences.preferences?.currentVocabulary !== id) {
      const preferenceResults = await dispatch(updatePreferences({ currentVocabulary: id }))
      if (!updatePreferences.fulfilled.match(preferenceResults)) {
        throw new Error('Failed to update preferences')
      }
    }
    return response.data
  },
)

export const updateCurrentVocabulary = createAsyncThunk(
  'vocabularies/updateVocabulary',
  async (updates: Partial<IVocabulary>, { getState }) => {
    const state = getState() as { vocabularies: VocabulariesState }
    const newVocabulary = { ...state.vocabularies.currentVocabulary, ...updates }
    const response = await axios.put(
      apiRoot + `/api/vocabulary/${newVocabulary.id}/`,
      newVocabulary,
    )
    return response.data
  },
)

interface EditPayload {
  operation: VocabularyOperation
  data: any
}

export type VocabularyOperation = 'addRow' | 'deleteRow' | 'deleteRows' | 'updateRow' | 'rearrangeRows'
export const editCurrentVocabulary = createAsyncThunk(
  'vocabularies/editCurrentVocabulary',
  async ({ operation, data }: EditPayload, { getState }) => {
    const state = getState() as { vocabularies: VocabulariesState }
    const newVocabulary = { ...state.vocabularies.currentVocabulary } as IVocabulary

    if (operation === 'addRow') {
      const position = data.position as number
      await axios.post(apiRoot + `/api/vocabulary/${newVocabulary.id}/modify_rows/`, {
        operation: 'insert_row',
        position: position + 1,
        left: '',
        right: '',
        vocabulary_id: newVocabulary.id,
      })
      return addRow(newVocabulary, position)
    }
    if (['deleteRow', 'deleteRows'].includes(operation)) {
      const positions = (operation === 'deleteRow') ? [data.position as number] : data.positions as number[]
      await axios.post(apiRoot + `/api/vocabulary/${newVocabulary.id}/modify_rows/`, {
        operation: 'delete_rows',
        positions,
        vocabulary_id: newVocabulary.id,
      })
      return
    }
    if (operation === 'updateRow') {
      const { row, position } = data as { row: IVocabularyRow, position: number }
      await axios.post(apiRoot + `/api/vocabulary/${newVocabulary.id}/modify_rows/`, {
        operation: 'update_row',
        vocabulary_id: newVocabulary.id,
        position,
        ...row,
      })
      return
    }
    if (operation === 'rearrangeRows') {
      const { rowIds } = data as { rowIds: number[] }
      await axios.post(apiRoot + `/api/vocabulary/${newVocabulary.id}/modify_rows/`, {
        operation: 'rearrange_rows',
        vocabulary_id: newVocabulary.id,
        row_order: rowIds,
      })
      return
    }
    throw new Error('Invalid operation')
  },
)
const addRow = (vocabulary: IVocabulary, position: number) => {
  const newRow = {
    id: 0,
    left: '',
    right: '',
    left_score: 0,
    right_score: 0,
  }
  const rows = vocabulary.rows
  return [...rows.slice(0, position + 1), newRow, ...rows.slice(position + 1)]
}
const deleteRows = (vocabulary: IVocabulary, positions: number[]) => {
  return vocabulary.rows.filter((_, index) => !positions.includes(index))
}
const _updateRow = (vocabulary: IVocabulary, row: IVocabularyRow, index: number) => {
  const rows = vocabulary.rows
  return [...rows.slice(0, index), row, ...rows.slice(index + 1)]
}

export const newVocabulary = createAsyncThunk(
  'vocabularies/newVocabulary',
  async (_, { getState }) => {
    const defaultLanguage = i18n.language
    const state = getState() as { vocabularies: VocabulariesState, auth: AuthState }
    const rightLanguage = state.vocabularies.currentVocabulary?.right_language || defaultLanguage
    const leftLanguage = (
      state.vocabularies.currentVocabulary?.left_language
      || ((defaultLanguage !== rightLanguage) ? defaultLanguage :
        (defaultLanguage !== 'en') ? 'en' :
          'es')
    )
    const response = await axios.post(apiRoot + '/api/vocabulary/', {
      left_language: leftLanguage,
      right_language: rightLanguage,
      owner: state.auth.user?.id,
      rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    })
    return response.data
  },
)
export const deleteCurrentVocabulary = createAsyncThunk(
  'vocabularies/deleteCurrentVocabulary',
  async (_, { getState, dispatch }) => {
    const state = getState() as { vocabularies: VocabulariesState }
    if (!state.vocabularies.currentVocabulary) return
    await axios.delete(apiRoot + `/api/vocabulary/${state.vocabularies.currentVocabulary.id}/`)
    // search for the previous vocabulary in the list
    let index: number | undefined = state.vocabularies.vocabularies.indexOf(state.vocabularies.currentVocabulary) - 1
    if (index < 0) {
      index = 0
    }
    if (state.vocabularies.vocabularies.length === 1) {
      index = undefined
    }
    console.log('new index', index, index && state.vocabularies.vocabularies[index])
    const newVocabularyId = index && state.vocabularies.vocabularies[index].id
    await dispatch(updatePreferences({ currentVocabulary: newVocabularyId }))
    return state.vocabularies.currentVocabulary.id
  },
)

export const splitCurrentVocabulary = createAsyncThunk(
  'vocabularies/splitCurrentVocabulary',
  async ({ position }: { position: number }, { getState, dispatch }) => {
    const state = getState() as { vocabularies: VocabulariesState }
    if (!state.vocabularies.currentVocabulary) return
    const vocabularyId = state.vocabularies.currentVocabulary.id
    const result = await axios.post(apiRoot + `/api/vocabulary/${vocabularyId}/modify_rows/`, {
      operation: 'split_vocabulary',
      vocabulary_id: vocabularyId,
      position,
    })
    return { position, newVocabulary: result.data }
  })
const splitRows = (vocabulary: IVocabulary, index: number) => {
  const rows = vocabulary.rows
  return [...rows.slice(0, index + 1)]
}

export interface IVocabularyRow {
  id: number
  left: string
  right: string
  left_score: number
  right_score: number
}

export interface IVocabulary {
  id: number
  title: string
  owner: number
  left_language: string
  right_language: string
  created: Date
  rows: Array<IVocabularyRow>
}

interface VocabulariesState {
  vocabularies: Array<{
    id: number
    title: string
    left_language: string
    right_language: string
    created: Date
  }>
  currentVocabulary: IVocabulary | null
  saving: boolean
  savingError: string
}

const vocabulariesSlice = createSlice({
  name: 'vocabularies',
  initialState: {
    vocabularies: [],
    currentVocabulary: null,
    saving: false,
    savingError: '',
  } as VocabulariesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateRow.fulfilled, (state, action) => {
        const row = action.payload
        const vocab = state.currentVocabulary
        if (!vocab) return
        const rows = vocab.rows.map((r) => (r.id === row.id ? { ...r, ...row } : r))
        state.currentVocabulary = { ...vocab, rows }
      })
      .addCase(listMyVocabularies.fulfilled, (state, action) => {
        state.vocabularies = action.payload
      })
      .addCase(setCurrentVocabulary.fulfilled, (state, action) => {
        state.currentVocabulary = action.payload
      })
      .addCase(updateCurrentVocabulary.pending, (state) => {
        state.saving = true
        state.savingError = ''
      })
      .addCase(updateCurrentVocabulary.fulfilled, (state, action) => {
        state.saving = false
        state.savingError = ''
        state.currentVocabulary = action.payload
        const vocab = (state.vocabularies.find(
            (v) => v.id === action.payload.id) as IVocabulary
        )
        vocab.title = action.payload.title
        vocab.left_language = action.payload.left_language
        vocab.right_language = action.payload.right_language
      })
      .addCase(updateCurrentVocabulary.rejected, (state, action) => {
        state.saving = false
        state.savingError = action.error.message?.toString() || 'unknown error'
      })
      .addCase(editCurrentVocabulary.pending, (state, action) => {
        state.saving = true
        state.savingError = ''
        if (!state.currentVocabulary) return
        const { operation, data } = action.meta.arg
        const newVocabulary = { ...state.currentVocabulary }
        if (operation === 'addRow') {
          state.currentVocabulary = { ...state.currentVocabulary, rows: addRow(newVocabulary, data.position) }
        } else if (operation === 'deleteRow') {
          state.currentVocabulary = { ...state.currentVocabulary, rows: deleteRows(newVocabulary, [data.position]) }
        } else if (operation === 'deleteRows') {
          state.currentVocabulary = { ...state.currentVocabulary, rows: deleteRows(newVocabulary, data.positions) }
        } else if (operation === 'updateRow') {
          state.currentVocabulary = {
            ...state.currentVocabulary,
            rows: _updateRow(newVocabulary, data.row, data.position),
          }
        } else if (operation === 'rearrangeRows') {
          const { rowIds } = data as { rowIds: number[] }
          const oldRows = state.currentVocabulary.rows
          const rows = rowIds.map((id) => oldRows.find((row) => row.id === id) as IVocabularyRow)
          state.currentVocabulary = { ...state.currentVocabulary, rows }
        }
      })
      .addCase(editCurrentVocabulary.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(editCurrentVocabulary.rejected, (state, action) => {
        state.saving = false
        state.savingError = action.error.message?.toString() || 'unknown error'
      })
      .addCase(newVocabulary.fulfilled, (state, action) => {
        const { id, title, left_language, right_language, created } = action.payload
        state.vocabularies = [{ id, title, left_language, right_language, created }, ...state.vocabularies]
        state.currentVocabulary = action.payload
      })
      .addCase(deleteCurrentVocabulary.fulfilled, (state, action) => {
        state.vocabularies = state.vocabularies.filter((v) => v.id !== action.payload)
        state.currentVocabulary = null
      })
      .addCase(splitCurrentVocabulary.pending, (state) => {
        state.saving = true
      })
      .addCase(splitCurrentVocabulary.fulfilled, (state, action) => {
        state.saving = false
        const { position } = action.meta.arg
        if (!state.currentVocabulary) return
        state.currentVocabulary = { ...state.currentVocabulary, rows: splitRows(state.currentVocabulary, position) }
        if (!action.payload) return
        state.vocabularies = [action.payload.newVocabulary, ...state.vocabularies]
      })
  },
})

export default vocabulariesSlice.reducer
