import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiRoot } from '../config'
import { RootState } from './index'
import { Extractor, getQueryLinks, hasDefinitions } from '../Utilities/queryLinks'

interface SearchParams {
  source: string;
  target: string;
  query: string;
}

// Define the async thunk
export const performSearch = createAsyncThunk(
  'search/fetchSearchResults',
  async ({ source, target, query }: SearchParams, { dispatch, getState }) => {
    const rootState = getState() as RootState
    const extractors = (rootState.search.results
      .filter(r=>!r.extractor.linkOnly)
      .map(r => r.extractor)
    )
    if (query) {
      await Promise.all(
        extractors.map((extractor) =>
          dispatch(searchOneProvider({ source, target, query, extractor })),
        ),
      )
    }
    return { source, target, query, extractors }
  })

interface SearchOneProviderParams {
  source: string;
  target: string;
  query: string;
  extractor: Extractor;
}

const searchOneProvider = createAsyncThunk(
  'search/fetchOneResult',
  async ({ source, target, query, extractor }: SearchOneProviderParams) => {

    // // Dummy results
    // console.log('TODO getOneResult', source, target, query, provider)
    // return { source, target, query, provider, results: `TODO ${provider}: ${query} (${source}->${target})` }

    const searchParams = new URLSearchParams({
      source,
      target,
      query,
      extractorName: extractor.name,
      groupName: {dictionaries: 'normal', definitions: 'definitions'}[extractor.category],
      operation: 'get_one'
    })
    const response = await axios.get(
      apiRoot + `/search-api/?${searchParams.toString()}`)
    return {
      extractorName: extractor.name,
      text: response.data.text,
      status: (response.data.success ? 'succeeded' : 'failed') as ResponseStatus
    }
  },
)

type ResponseStatus = 'loading' | 'succeeded' | 'failed' | 'error';

type Response = {
  text?: string;
  status: ResponseStatus;
  statusText?: string;
};

type Result = {
  response: Response;
  extractor: Extractor;
};


interface SearchState {
  activeTab: 'dictionaries' | 'definitions';
  currentSearch: SearchParams | null;
  results: Result[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

// Define the slice
const searchSlice = createSlice({
  name: 'search',
  initialState: {
    currentSearch: null,
    activeTab: 'dictionaries',
    results: [],
    status: 'idle',
    error: null,
  } as SearchState,
  reducers: {
    setActiveTab(state, action) {
      state.activeTab = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(performSearch.pending, (state, action) => {
        // sets up the search
        console.log('performSearch.pending', action)
        const { source, target, query } = action.meta.arg
        state.currentSearch = { source, target, query }
        if (!hasDefinitions(source)) {
          state.activeTab = 'dictionaries'
        }
        const providers = getQueryLinks(source, target, query)
        state.results = providers.map(extractor => ({
          extractor,
          response: { status: extractor.linkOnly ? 'succeeded' : 'loading' },
        }))
        state.status = 'loading'
      })
      .addCase(performSearch.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(performSearch.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || ''
      })
      .addCase(searchOneProvider.fulfilled, (state, action) => {
        const { extractorName, text, status } = action.payload
        const ix = state.results.findIndex(r => r.extractor.name === extractorName)
        state.results = [...state.results]
        state.results[ix] = {
          extractor: state.results[ix].extractor,
          response: { text, status },
        }
      })
      .addCase(searchOneProvider.rejected, (state, action) => {
        const ix = state.results.findIndex(r => r.extractor.name === action.meta.arg.extractor.name)
        state.results = [...state.results]
        state.results[ix] = {
          extractor: state.results[ix].extractor,
          response: { status: 'error', statusText: action.error.message || '' },
        }
      })
  },
})

// Export the actions and the reducer
export default searchSlice.reducer
export const { setActiveTab } = searchSlice.actions