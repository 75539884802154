import React, { useEffect, useState } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { Done as DoneIcon, Error as ErrorIcon, MoreHoriz } from '@mui/icons-material'
import { useAppSelector } from '../hooks'
import { useTranslation } from 'react-i18next'

const CurrentVocabularyStatus = () => {
  const { t } = useTranslation()
  const saving = useAppSelector((state) => state.vocabularies.saving)
  const savingError = useAppSelector((state) => state.vocabularies.savingError)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const [changed, setChanged] = useState(false)
  const currentVocabularyId = useAppSelector((state) => state.preferences.preferences?.currentVocabulary)

  useEffect(() => {
    setChanged(false)
  }, [currentVocabularyId])

  const handleTooltipOpen = () => {
    setTooltipOpen(true)
  }

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  if (saving) {
    if (!changed) setChanged(true)

    return <Tooltip title={t('SavingVocabulary')} open={tooltipOpen} onClose={handleTooltipClose}>
      <IconButton onClick={handleTooltipOpen}>
        <MoreHoriz />
      </IconButton>
    </Tooltip>
  }

  if (savingError) {
    return (
      <Tooltip title={t(savingError)} open={tooltipOpen} onClose={handleTooltipClose}>
        <IconButton onClick={handleTooltipOpen}>
          <ErrorIcon color="error" />
        </IconButton>
      </Tooltip>
    )
  }
  if (changed) {
    return (
      <Tooltip title={t('VocabularySaved')} open={tooltipOpen} onClose={handleTooltipClose}>
        <IconButton onClick={() => {
          handleTooltipOpen()
          setTimeout(() => {
            handleTooltipClose()
          }, 1900)
          setTimeout(() => {
            setChanged(false)
          }, 2000)
        }}>
          <DoneIcon />
        </IconButton>
      </Tooltip>
    )
  }
  return <></>
}

export default CurrentVocabularyStatus