import React from 'react'
import SearchBar from './SearchBar'
import SearchResults from './SearchResults'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import SearchTabs from './SearchTabs'

const SearchPanel = ({ onCloseSearch }: { onCloseSearch: () => void }) => {
  const { t } = useTranslation()

  return (
    <div className="search-panel">
      <div style={{ maxWidth: '500px', margin: '0 auto' }}>

        <IconButton
          onClick={onCloseSearch}
          sx={{ float: 'right' }}
          className="toggle-vocabulary">
          <Close />
        </IconButton>

        <h2>{t('Search')}</h2>

        <SearchBar />
        <SearchTabs />
        <SearchResults />
      </div>
    </div>
  )
}

export default SearchPanel
