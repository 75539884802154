import { useEffect } from 'react'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'

import Navbar from './Components/Navbar'
import CardsPage from './Routes/CardsPage'
import LandingPage from './Routes/LandingPage'
import LoginPage from './Routes/LoginPage'
import ProfilePage from './Routes/ProfilePage'
import RegisterPage from './Routes/RegisterPage'
import './index.css'

import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import PrivateRoute from './Components/PrivateRoute'
import { getUserPreferences } from './Store/PreferencesSlice'
import { useAppDispatch, useAppSelector } from './hooks'
import NotFoundPage from './Routes/NotFoundPage'
import { logOut } from './Store/AuthSlice'

let loaded = false

function App() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn)
  const navigate = useNavigate()

  const loadPreferences = async () => {
    const result = await dispatch(getUserPreferences())
    if (getUserPreferences.rejected.match(result)) {
      console.log(result)
      if (!isLoggedIn && result.error.message === 'No user id') {
        return
      }
      if (result.error.message === 'Unauthorized') {
        toast.error(t('SessionExpired'))
        await dispatch(logOut())
        navigate('/login')
        return
      }
      toast.error(t('ErrorLoadingPage'))
    }
  }
  useEffect(() => {
    if (!loaded) {
      loadPreferences()
    }
    loaded = true
  }, [dispatch])
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<PrivateRoute children={<LandingPage />} />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route
          path="/profile"
          element={<PrivateRoute children={<ProfilePage />} />}
        />
        <Route
          path="/cards"
          element={<PrivateRoute children={<CardsPage gameType={'ImprovedBlockCardsGame'} />} />}
        />
        <Route
          path="/cards/numbers"
          element={<PrivateRoute children={<CardsPage gameType={'NumbersGame'} />} />}
        />
        <Route
          path="/cards/hanzi"
          element={<PrivateRoute children={<CardsPage gameType={'ZhCharactersGame'} />} />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  )
}

export default App
