import { configureStore } from '@reduxjs/toolkit'
import authReducer from './AuthSlice'
import preferencesReducer from './PreferencesSlice'
import vocabulariesReducer from './VocabulariesSlice'
import gameReducer from './GameSlice'
import searchReducer from './SearchSlice'
import pronounceReducer from './PronounceSlice'

const store = configureStore({
  reducer: {
    auth: authReducer,
    preferences: preferencesReducer,
    vocabularies: vocabulariesReducer,
    game: gameReducer,
    search: searchReducer,
    pronounce: pronounceReducer,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(dispatchOrWaitMiddleware),
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
