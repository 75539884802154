import React, { FC, useEffect, useMemo, useState } from 'react'
import { Box, Button } from '@mui/material'
import { useAutoPronounce } from '../Utilities/useAutoPronounce'
import { useTranslation } from 'react-i18next'
import {
  Close,
  ContentCopy,
  Done,
  Edit,
  Help,
  KeyboardArrowDown,
  QuestionMark,
  Settings,
  SkipNext,
  SkipPrevious,
  VolumeDown,
} from '@mui/icons-material'

import './CardsPlay.less'
import { useAppDispatch, useAppSelector } from '../hooks'
import { Direction, GameType, getNextCard, levelDown, levelUp, startGame } from '../Store/GameSlice'
import { toast } from 'react-toastify'
import { sanitizeCell } from '../Utilities/tools'
import VocabularyTools, { DisplayTool } from './VocabularyTools'
import { updateRow } from '../Store/VocabulariesSlice'
import { pronounce } from '../Store/PronounceSlice'
import { ThemeContext } from '../Providers/ThemeContext'

export interface PlayPreferences {
  direction: Direction
  showPinyin?: boolean
  forceNewGame: boolean
  gameType: GameType
  infoLanguage?: string
  secretLanguage?: string
}

interface CardsPlayProps {
  preferences: PlayPreferences
  onDemo: () => void
  onSetup: () => void
  onFinished: (message: string) => void
}

type InteractionState = 'showQuestion' | 'evaluate.normal' | 'evaluate.wrong' | 'disabled' | 'edit'

const CardsPlay: FC<CardsPlayProps> = ({ preferences, onDemo, onSetup, onFinished }) => {
  const { t } = useTranslation()
  const { darkMode } = React.useContext(ThemeContext)

  const dispatch = useAppDispatch()

  const [loaded, setLoaded] = useState(true)
  const [interactionState, setInteractionState] = useState<InteractionState>('disabled')

  const progressSelector = useAppSelector(state => state.game.currentGame?.state?.progress)
  const progress = useMemo(() => {
    return progressSelector || { value: 0, text: '' }
  }, [progressSelector])
  const message = useAppSelector(state => state.game.currentGame?.state.message || '')
  const [showMessage, setShowMessage] = useState(false)
  const [celebrationfadeOut, setCelebrationfadeOut] = useState(false)

  useEffect(() => {
    if (message) {
      setShowMessage(true)
      setCelebrationfadeOut(false)
      setTimeout(() => setCelebrationfadeOut(true), 1300)
      setTimeout(() => setShowMessage(false), 1500)
    }
  }, [message])

  const currentCardSelector = useAppSelector(state => state.game.currentGame?.state.current_card)
  const currentCard = useMemo(() => currentCardSelector || {
    info: '',
    secret: '',
    row: 0,
  }, [currentCardSelector])
  const coverPosition = useMemo(() => (interactionState === 'showQuestion') ? 'up' : 'down', [interactionState])

  const currentVocabularyId = useAppSelector(state => state.vocabularies.currentVocabulary?.id)

  const nextArrived = true

  const doStartGame = async () => {
    const v = Number(new URLSearchParams(window.location.search).get('v'))
    if (preferences.gameType.endsWith('BlockCardsGame')) {
      const vocabularyId = v || currentVocabularyId
      if (!vocabularyId) {
        toast.error(t('cardShow.errorGettingVocabularyId'))
        setInteractionState('disabled')
        return
      }
      return dispatch(startGame({
        vocabularyId,
        ...preferences,
      }))
    }
    return dispatch(startGame({
      ...preferences,
    }))
  }

  const showLevelChangers = useMemo(() => preferences.gameType === 'NumbersGame', [preferences.gameType])
  const level = useAppSelector(state => state.game.currentGame?.state?.level || 0)
  const maxLevel = useAppSelector(state => (state.game.currentGame?.state?.levels || 1) - 1)

  const onLevelDown = () => {
    if (level === 0) return
    dispatch(levelDown())
  }
  const onLevelUp = () => {
    if (level >= maxLevel) return
    dispatch(levelUp())
  }

  useEffect(() => {
    (async () => {
      console.log('start play', preferences)
      const response = await doStartGame()
      if (!startGame.fulfilled.match(response)) {
        toast.error(t('cardShow.errorStartingGame'))
        setInteractionState('disabled')
        return
      }
      setInteractionState('showQuestion')
      autoPronounceInfo()
    })()
  }, [])

  useEffect(() => {
    if (interactionState === 'showQuestion' && !currentCard.info) {
      onFinished(message)
    }
  }, [currentCard, interactionState])

  const showSecret = () => {
    setInteractionState('evaluate.normal')
    autoPronounceSecret()
  }

  const onAnswer = async (answer: boolean) => {
    const previousState = interactionState
    setInteractionState('showQuestion')
    setLoaded(false)
    const result = await dispatch(getNextCard({ answer }))
    setLoaded(true)
    if (!getNextCard.fulfilled.match(result)) {
      toast.error(t('cardShow.errorGettingNextCard'))
      setInteractionState(previousState)
      return
    }
    autoPronounceInfo()
  }
  const onCorrect = async () => {
    await onAnswer(true)
  }
  const onWrong = async () => {
    await onAnswer(false)
  }

  const onQuestionMark = () => {
    setInteractionState('evaluate.wrong')
    autoPronounceSecret()
  }
  const onOk = async () => {
    await onWrong()
  }

  const [displayTools, setDisplayTools] = useState<DisplayTool>('')
  const infoLanguage = useMemo(() => preferences.infoLanguage || '', [preferences.infoLanguage])
  const secretLanguage = useMemo(() => preferences.secretLanguage || '', [preferences.secretLanguage])
  const pronounceInfo = () => {
    if (!infoLanguage) return Promise.resolve()
    return dispatch(pronounce({ text: currentCard.info, language: infoLanguage }))
  }
  const pronounceSecret = () => {
    if (!secretLanguage) return Promise.resolve()
    if (preferences.gameType === 'NumbersGame' && secretLanguage === 'ar') {
      return dispatch(pronounce({ text: currentCard.info, language: 'ar' }))
    }
    return dispatch(pronounce({ text: currentCard.secret, language: secretLanguage }))
  }
  const infoPronounced = useAppSelector(state => {
    const pronunciations = state.preferences.preferences?.cardPronunciations || {}
    if (!infoLanguage) return false
    if (preferences.gameType === 'NumbersGame') return false
    if (!(infoLanguage in pronunciations)) return true
    return pronunciations[infoLanguage]
  })
  const secretPronounced = useAppSelector(state => {
    const pronunciations = state.preferences.preferences?.cardPronunciations || {}
    if (!secretLanguage) return false
    if (!(secretLanguage in pronunciations)) return true
    return pronunciations[secretLanguage]
  })
  const autoPronounceInfo = useAutoPronounce(infoPronounced, pronounceInfo)
  const autoPronounceSecret = useAutoPronounce(secretPronounced, pronounceSecret)

  /* Edit card */
  const onEdit = () => {
    if (interactionState === 'edit') {
      setInteractionState('evaluate.normal')
      return
    }
    setDisplayTools('')
    setInteractionState('edit')
  }
  const [editInfo, setEditInfo] = useState('')
  const [editSecret, setEditSecret] = useState('')

  useEffect(() => {
    if (interactionState !== 'edit') return
    setEditInfo(currentCard.info)
    setEditSecret(currentCard.secret)
  }, [interactionState])

  const onSaveEdit = async () => {
    let left, right
    if (preferences.direction === 'ltr') {
      left = editInfo
      right = editSecret
    } else {
      left = editSecret
      right = editInfo
    }
    const results = await dispatch(updateRow({ id: currentCard.row, left, right }))
    if (!updateRow.fulfilled.match(results)) {
      toast.error(t('cardShow.errorSavingRow'))
      return
    }
    setInteractionState('evaluate.normal')
  }

  return (
    <div className={`play ${preferences.gameType}`}>
      <div></div>
      {/* This is a hack to fix the layout */}
      <div className="cardshow">
        <div className="display">
          {!loaded && (
            <div className="card-container">
              <Box display="flex" justifyContent="center">
                {t('cardShow.loading')}
              </Box>
            </div>
          )}
          <div
            className={`card-container ${preferences?.showPinyin ? 'showPinyin' : 'hidePinyin'}`}
          >
            <div className="card shadow2"></div>
            <div className="card shadow1"></div>
            <div className="card inner">
              <div className="info">
                <div
                  className="displayed-value"
                  lang={infoLanguage}
                  contentEditable={interactionState === 'edit'}
                  suppressContentEditableWarning
                  dangerouslySetInnerHTML={{
                    __html: sanitizeCell(currentCard.info),
                  }}
                  onBlur={(e) => setEditInfo(sanitizeCell(e.target.innerHTML))}
                  data-testid="info"
                />
                <VocabularyTools
                  value={currentCard.info}
                  displayTools={displayTools}
                  language={infoLanguage}
                />
              </div>
              <div className="secret">
                <div
                  className="displayed-value"
                  lang={secretLanguage}
                  contentEditable={interactionState === 'edit'}
                  suppressContentEditableWarning
                  dangerouslySetInnerHTML={{
                    __html: sanitizeCell(currentCard.secret),
                  }}
                  onBlur={(e) =>
                    setEditSecret(sanitizeCell(e.target.innerHTML))
                  }
                  data-testid="secret"
                />
                <VocabularyTools
                  value={currentCard.secret}
                  displayTools={displayTools}
                  language={secretLanguage}
                />
              </div>
            </div>
            <div className={`card cover ${coverPosition}`} onClick={showSecret} data-testid="show">
              <center>
                {interactionState === 'showQuestion' && nextArrived && (
                  <KeyboardArrowDown className="question-arrow-down" />
                )}
              </center>
            </div>
          </div>
        </div>
        <div className="navigate">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
            sx={{ padding: 2 }}
          >
            {showLevelChangers && (
              <Button
                className="action small"
                style={{ border: 'none' }}
                onClick={onLevelDown}
                disabled={level === 0}
                color="secondary"
              >
                <SkipPrevious />
              </Button>
            )}
            {interactionState === 'evaluate.normal' && (
              <>
                <Button
                  className="action"
                  onClick={onCorrect}
                  variant={darkMode ? 'outlined' : 'contained'}
                  color="success"
                  data-testid="correct"
                >
                  <Done />
                </Button>
                <Button
                  className="action"
                  onClick={onWrong}
                  variant={darkMode ? 'outlined' : 'contained'}
                  color="error"
                  data-testid="wrong"
                >
                  <Close />
                </Button>
              </>
            )}

            {interactionState === 'showQuestion' && (
              <>
                <Button
                  className="action"
                  onClick={onCorrect}
                  variant="contained"
                  color="success"
                  disabled
                  data-testid="correct"
                >
                  <Done />
                </Button>
                <Button
                  className="action"
                  onClick={onQuestionMark}
                  color="secondary"
                  data-testid="dontknow"
                >
                  <QuestionMark />
                </Button>
              </>
            )}
            {interactionState === 'evaluate.wrong' && (
              <>
                <Button
                  className="action"
                  onClick={onCorrect}
                  variant="contained"
                  color="success"
                  disabled
                  data-testid="correct"
                >
                  <Done />
                </Button>
                <Button className="action" color="secondary" onClick={onOk} data-testid="ok">
                  {t('OK')}
                </Button>
              </>
            )}
            {interactionState === 'edit' && (
              <>
                <Button className="action" onClick={onSaveEdit} data-testid="edit-save">
                  {t('OK')}
                </Button>
                <Button
                  className="action"
                  onClick={() => setInteractionState('evaluate.normal')}
                  data-testid="edit-cancel"
                >
                  {t('Cancel')}
                </Button>
              </>
            )}
            {showLevelChangers && (
              <Button
                className="action small"
                style={{ border: 'none' }}
                onClick={onLevelUp}
                disabled={level >= maxLevel}
                color="secondary"
              >
                <SkipNext />
              </Button>
            )}
          </Box>

          <div className="progress-info">{progress.text}</div>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={progress.value}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{ width: `${progress.value}%` }}
            ></div>
          </div>
        </div>
        {showMessage && (
          <div
            className={`celebration ${celebrationfadeOut ? 'fade-out' : ''}`}
          >
            {message}
          </div>
        )}
      </div>

      <Box display="flex" justifyContent="space-between">
        <Button color="secondary" onClick={() => onSetup()}>
          <Settings />
        </Button>
        <Button
          color="secondary"
          onClick={() =>
            setDisplayTools(displayTools !== 'pronounce' ? 'pronounce' : '')
          }
        >
          <VolumeDown />
        </Button>
        <Button color="secondary" onClick={onEdit}>
          <Edit />
        </Button>
        <Button
          color="secondary"
          onClick={() => setDisplayTools(displayTools !== 'copy' ? 'copy' : '')}
        >
          <ContentCopy />
        </Button>
        <Button color="secondary" onClick={() => onDemo()}>
          <Help />
        </Button>
      </Box>
    </div>
  )
}

export default CardsPlay
