import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function NotFoundPage() {
  const { t } = useTranslation()

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <h1>{t('404.title')}</h1>
      <p>{t('404.text')}</p>
      <Link to="/">{t('404.back')}</Link>
    </div>
  )
}

export default NotFoundPage
