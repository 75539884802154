import { Navigate } from 'react-router-dom'
import { useAppSelector } from '../hooks'

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn)
  const userId = useAppSelector((state) => state.auth.user?.id || 0)
  if (!isLoggedIn) {
    return <Navigate to="/register" />
  }
  if (!userId) {
    return <></>
  }
  return <>{children}</>
}
export default PrivateRoute
