import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material'
import {
  Add,
  AddCircle,
  ContentCopy,
  Delete,
  FlipCameraAndroid,
  MoreVert,
  PlayArrow,
  Search,
  Share,
  VolumeDown,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import LanguageSourceTarget from './LanguageSourceTarget'
import Vocabulary, { VocabularyHandle } from './Vocabulary'

import './VocabularyPanel.less'
import { useAppDispatch, useAppSelector } from '../hooks'
import {
  deleteCurrentVocabulary,
  editCurrentVocabulary,
  IVocabularyRow,
  listMyVocabularies,
  newVocabulary, splitCurrentVocabulary,
  updateCurrentVocabulary,
  VocabularyOperation,
} from '../Store/VocabulariesSlice'
import { toast } from 'react-toastify'
import CurrentVocabularyStatus from './CurrentVocabularyStatus'
import CurrentVocabularyLineCount from './CurrentVocabularyLineCount'
import { useNavigate } from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import { DisplayTool } from './VocabularyTools'
import VocabularyShareDialog from './VocabularyShareDialog'
import { performSearch } from '../Store/SearchSlice'
import { stopGame } from '../Store/GameSlice'
import { text_from_html } from '../Utilities/tools'

interface VocabularyPanelProps {
  onSelectVocabulary: (n: number) => void;
  onSearch: (params: { query: string, source: string, target: string }) => void;
}

const VocabularyPanel = forwardRef<React.RefObject<HTMLDivElement>, VocabularyPanelProps>(
  ({ onSelectVocabulary, onSearch }, ref) => {
    const navigate = useNavigate()
    const panelRef = useRef<HTMLDivElement>(null)
    useImperativeHandle(ref, () => ({
      current: panelRef.current,
    }))
    const { t } = useTranslation()

    // Scrolling

    const [isScrolling, setIsScrolling] = useState(false)
    useEffect(() => {
      const handleScroll = () => {
        if (panelRef.current) {
          setIsScrolling(panelRef.current.scrollTop > 0)
        }
      }
      const panel = panelRef.current
      if (panel) {
        panel.addEventListener('scroll', handleScroll)
      }

      return () => {
        if (panel) {
          panel.removeEventListener('scroll', handleScroll)
        }
      }
    }, [])

    // List vocabularies

    const dispatch = useAppDispatch()

    const vocabularies = useAppSelector(
      (state) => state.vocabularies.vocabularies,
    )
    useEffect(() => {
      const loaded = vocabularies.length > 0
      if (!loaded) {
        dispatch(listMyVocabularies())
      }
    }, [])

    // Current vocabulary actions

    const currentVocabulary = useAppSelector(
      (state) => state.vocabularies.currentVocabulary,
    )
    const isVocabularyEmpty = useMemo<boolean>(
      () => (!currentVocabulary?.rows.filter(r => r.left && r.right).length)
      , [currentVocabulary])

    const vocabularyTitle = useMemo<string>(() => {
      return currentVocabulary?.title || ''
    }, [currentVocabulary])
    const [vocabularyTitleInput, setVocabularyTitleInput] = useState(vocabularyTitle)
    useEffect(() => {
      setVocabularyTitleInput(vocabularyTitle)
    }, [vocabularyTitle])
    const setVocabularyTitle = (title: string) => {
      dispatch(updateCurrentVocabulary({ title }))
    }
    const sourceLanguage = useMemo<string>(() => {
      return currentVocabulary?.left_language || 'en'
    }, [currentVocabulary])
    const targetLanguage = useMemo<string>(() => {
      return currentVocabulary?.right_language || 'hu'
    }, [currentVocabulary])
    const updateLanguages = async ({ source, target }: { source: string, target: string }) => {
      const data = { left_language: source, right_language: target }
      await dispatch(updateCurrentVocabulary(data))
    }

    const handleNewVocabulary = async () => {
      const response = await dispatch(newVocabulary())
      if (newVocabulary.fulfilled.match(response)) {
        const id = response.payload.id
        navigate(`?v=${id}`)
      } else {
        toast.error(t('ErrorCreatingVocabulary'))
      }
    }

    // Vocabulary Toolbar actions
    const action = (operation: VocabularyOperation | 'flipRow') => {
      if (!vocabularyRef.current) return
      if (!currentVocabulary) return
      const position = vocabularyRef.current.getCurrentRow().index
      let data
      if (['addRow', 'deleteRow'].includes(operation)) {
        dispatch(stopGame())
        data = { position }
      } else {
        data = { position, row: currentVocabulary.rows[position] }
      }
      if (operation === 'flipRow') {
        operation = 'updateRow'
        const row = data.row as IVocabularyRow
        data = { position, row: { ...data.row, left: row.right, right: row.left } }
      }
      dispatch(editCurrentVocabulary({ operation, data }))
    }
    const handleVocabularyChange = (data: any) => {
      if (data.operation === 'updateRow') {
        const { position, row } = data
        dispatch(editCurrentVocabulary({ operation: 'updateRow', data: { position, row } }))
      }
      if (data.operation === 'addRow') {
        const { position } = data
        dispatch(editCurrentVocabulary({ operation: 'addRow', data: { position } }))
      }
      if (data.operation === 'rearrangeRows') {
        const { rowIds } = data
        dispatch(editCurrentVocabulary({ operation: 'rearrangeRows', data: { rowIds } }))
      }
    }

    // More menu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const openMoreMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const closeMoreMenu = () => {
      setAnchorEl(null)
    }
    // Which tool to display in the vocabulary...
    const [displayTools, setDisplayTools] = useState<DisplayTool>('')

    const vocabularyRef = useRef<VocabularyHandle | null>()
    const handleSearch = () => {
      if (!vocabularyRef.current || !currentVocabulary) return
      const { rowIndex, column } = vocabularyRef.current.getCurrentCell()
      const query = text_from_html(currentVocabulary.rows[rowIndex][column])
      const [source, target] = (column === 'left') ? [sourceLanguage, targetLanguage] : [targetLanguage, sourceLanguage]
      dispatch(performSearch({ query, source, target }))
      onSearch({ query, source, target })
    }
    const handleLearnWords = () => {
      navigate(`/cards?v=${currentVocabulary?.id}`)
    }

    const confirm = useConfirm()
    const handleDeleteCurrentVocabulary = async () => {
      confirm({ description: t('WarningPermanentAction') })
        .then(async () => {
          const result = await dispatch(deleteCurrentVocabulary())
          if (deleteCurrentVocabulary.fulfilled.match(result)) {
            toast.success(t('VocabularyDeleted'))
            navigate('/')
          } else {
            toast.error(t('ErrorDeletingVocabulary'))
          }
        })
        .catch(() => {
          // Do nothing
        })
    }

    const splitVocabulary = async () => {
      const position = vocabularyRef.current?.getCurrentRow().index
      if (position === undefined) return
      confirm({ description: t('WarningPermanentAction') })
        .then(async () => {
          const result = await dispatch(splitCurrentVocabulary({ position }))
          if (splitCurrentVocabulary.fulfilled.match(result)) {
            toast.success(t('VocabularySplitInfo', { title: result.payload?.newVocabulary.title as string }))
          } else {
            toast.error(t('ErrorSplittingVocabulary'))
          }
        })
        .catch(() => {
          // Do nothing
        })
    }
    const [openShareDialog, setOpenShareDialog] = useState(false)

    const currentLeftLanguage = useMemo(() => currentVocabulary?.left_language || '', [currentVocabulary])

    const sortedVocabularies = useMemo(() => {
      return [...vocabularies].sort((a, b) => {
        if (a.left_language !== b.left_language) return (a.left_language === currentLeftLanguage) ? -1 : 1
        return a.created > b.created ? -1 : 1
      })
    }, [vocabularies, currentLeftLanguage])
    return (
      <div
        ref={panelRef}
        className={`vocabulary-panel ${isScrolling ? 'scrolling' : ''}`}
      >
        <VocabularyShareDialog
          open={openShareDialog}
          onClose={() => setOpenShareDialog(false)}
          vocabularyId={currentVocabulary?.id || 0} />
        <div className="container">
          <div className="vocabulary-toolbar">
            <Tooltip title={t('tooltip.Search')}>
              <IconButton onClick={handleSearch}>
                <Search />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('tooltip.Add')}>
              <IconButton onClick={() => action('addRow')}>
                <Add />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('tooltip.Delete')}>
              <IconButton onClick={() => action('deleteRow')}>
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('tooltip.Learn')}>
              <span>
                <IconButton onClick={handleLearnWords} disabled={isVocabularyEmpty} className="emphasized-button">
                  <PlayArrow />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t('tooltip.More')}>
              <IconButton onClick={openMoreMenu}>
                <MoreVert />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={closeMoreMenu}
            >
              <MenuItem
                onClick={() => {
                  setOpenShareDialog(true)
                  closeMoreMenu()
                }}
              >
                <ListItemIcon>
                  <Share fontSize="small" />
                </ListItemIcon>
                {t('tooltip.Share')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  splitVocabulary()
                  closeMoreMenu()
                }}>
                <ListItemIcon/>
                {t('SplitVocabulary')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  action('flipRow')
                  closeMoreMenu()
                }}
              >
                <ListItemIcon>
                  <FlipCameraAndroid fontSize="small" />
                </ListItemIcon>
                {t('tooltip.FlipRow')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setDisplayTools(
                    displayTools !== 'pronounce' ? 'pronounce' : '',
                  )
                  closeMoreMenu()
                }}
              >
                <ListItemIcon>
                  <VolumeDown fontSize="small" />
                </ListItemIcon>
                {t('ShowHidePronunciations')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setDisplayTools(displayTools !== 'copy' ? 'copy' : '')
                  closeMoreMenu()
                }}
              >
                <ListItemIcon>
                  <ContentCopy fontSize="small" />
                </ListItemIcon>
                {t('ShowHideCopyButtons')}
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  handleDeleteCurrentVocabulary()
                  closeMoreMenu()
                }}>
                <ListItemIcon />
                {t('DeleteVocabulary')}
              </MenuItem>
            </Menu>
            <div style={{ flexGrow: 1 }} />
            <CurrentVocabularyStatus />
            <CurrentVocabularyLineCount />
          </div>
          <div style={{ display: 'flex', marginBottom: '10px' }}>
            <TextField
              value={vocabularyTitleInput}
              placeholder={t('VocabularyTitlePlaceholder')}
              onChange={(event) => setVocabularyTitleInput(event.target.value)}
              onBlur={() => setVocabularyTitle(vocabularyTitleInput)}
              sx={{
                '& .MuiInputBase-root': { paddingRight: 0 },
                flexGrow: 1,
              }}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <LanguageSourceTarget
                        sourceLanguage={sourceLanguage}
                        targetLanguage={targetLanguage}
                        onChange={updateLanguages}
                      />
                    </InputAdornment>
                  </>
                ),
              }}
            />
            <Select
              className="vocabulary-select"
              value={currentVocabulary?.id || 0}
              onChange={(e) => onSelectVocabulary(e.target.value as number)}
              renderValue={() => ''}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
              }}
              data-testid="vocabulary-select"
            >
              <MenuItem onClick={handleNewVocabulary}>
                <ListItemIcon>
                  <AddCircle />
                </ListItemIcon>
                {t('AddNewVocabulary')}
              </MenuItem>
              <Divider />
              {sortedVocabularies.map(({ id, title, left_language, right_language }) => (
                <MenuItem key={id} value={id}>
                  <Box display="flex" justifyContent="space-between" width="100%" gap={2}>
                    <span className="vocab-menu-title">{title || t('UntitledVocabulary')}</span>
                    <span>{left_language}→{right_language}</span>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </div>
          {currentVocabulary &&
            <Vocabulary ref={vocabularyRef} vocabulary={currentVocabulary} displayTools={displayTools}
                        onChange={handleVocabularyChange} />}
        </div>
      </div>
    )
  },
)

export default VocabularyPanel
