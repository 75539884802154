// CardsSetup.tsx
import React, { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../hooks'
import { Direction, GameType } from '../Store/GameSlice'
import { Case, CaseElse, ValueSwitch } from '../Utilities/Switch'
import { languageNames } from '../config'


export type CardSetupData = {
  direction: Direction
  pronunciations: { [key: string]: boolean }
  skipNextTime: boolean
  showPinyin?: boolean
  forceNewGame: boolean
  gameType: GameType
  infoLanguage?: string
  secretLanguage?: string
}

type CardsSetupProps = {
  gameType: GameType
  onSubmit: (data: CardSetupData) => void
}

const CardsSetup: React.FC<CardsSetupProps> = ({ gameType, onSubmit }) => {
  const { t } = useTranslation()
  const preferences = useAppSelector((state) => state.preferences.preferences)
  const [gameLanguage, setGameLanguage] = useState<string>(preferences?.lastGameLanguage || 'es')
  const [pronunciations, setPronunciations] = useState(preferences?.cardPronunciations || {})

  const [direction, setDirection] = useState(preferences?.cardDirection || 'ltr')
  const pronunciationOn = (language: string | undefined) => {
    if (!language) return false
    return (language in pronunciations) ? pronunciations[language] : true
  }

  function changePronunciation(language: string | undefined, value: boolean) {
    if (!language) return
    const newPronunciation = { ...pronunciations }
    newPronunciation[language] = value
    setPronunciations(newPronunciation)
  }

  const [skipNextTime, setSkipNextTime] = useState(preferences?.cardStartSlide === 'play')
  const [showPinyin, setShowPinyin] = useState<boolean>(preferences?.cardShowPinyin || false)
  const ableToContinue = useAppSelector((state) => {
    if (!state.game.currentGame) return false
    const currentGameType = state.game.currentGame.gameType
    if (currentGameType !== gameType) return false

    if (currentGameType === 'NumbersGame')
      return state.preferences.preferences?.lastGameLanguage === gameLanguage
    if (currentGameType === 'ZhCharactersGame')
      return true
    return (
      (state.game.vocabularyId === state.vocabularies.currentVocabulary?.id)
      && (state.preferences.preferences?.cardDirection === direction)
    )
  })

  const [clickedButton, setClickedButton] = useState<string>('')
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const forceNewGame = clickedButton === 'start'
    let infoLanguage = currentVocabulary?.left_language
    let secretLanguage = currentVocabulary?.right_language
    let myDirection = direction
    if (gameType.endsWith('BlockCardsGame')) {
      setGameLanguage(currentVocabulary?.left_language || 'es')
      if (direction === 'rtl') {
        const temp = infoLanguage
        infoLanguage = secretLanguage
        secretLanguage = temp
      }
    } else if (gameType === 'NumbersGame') {
      secretLanguage = gameLanguage
      infoLanguage = gameLanguage
    } else if (gameType === 'ZhCharactersGame') {
      myDirection = 'ltr'
      infoLanguage = 'zh'
      secretLanguage = undefined
    }
    onSubmit({
      gameType,
      direction: myDirection,
      pronunciations,
      skipNextTime,
      showPinyin,
      forceNewGame,
      infoLanguage,
      secretLanguage,
    })
  }

  const currentVocabulary = useAppSelector((state) => state.vocabularies.currentVocabulary)

  const leftToRightLabel = useMemo(() =>
      (currentVocabulary?.left_language === currentVocabulary?.right_language) ?
        t('leftToRight') :
        currentVocabulary?.left_language + ' → ' + currentVocabulary?.right_language
    , [t, currentVocabulary])
  const rightToLeftLabel = useMemo(() =>
      (currentVocabulary?.left_language === currentVocabulary?.right_language) ?
        t('rightToLeft') :
        currentVocabulary?.right_language + ' → ' + currentVocabulary?.left_language
    , [t, currentVocabulary])

  const hasPinyin = useMemo(
    () =>
      gameType === 'ZhCharactersGame'
      || (gameType.endsWith('BlockCardsGame') && (currentVocabulary?.left_language === 'zh' || currentVocabulary?.right_language === 'zh')),
    [gameType, currentVocabulary],
  )

  return (
    <form onSubmit={handleSubmit}>
      <Container maxWidth="xs" sx={{ alignItems: 'center', marginTop: '20px' }}>
        <Stack spacing={2}>
          <h1>{t('setup')}</h1>
          <Box display="flex" sx={{ flexDirection: 'column' }} gap={2}>
            <ValueSwitch test={gameType}>
              <Case value="NumbersGame">
                <FormControl component="fieldset">
                  <InputLabel id="language-select-label">{t('SelectLanguage')}</InputLabel>
                  <Select
                    labelId="language-select-label"
                    value={gameLanguage}
                    onChange={(event) => setGameLanguage(event.target.value as string)}
                    data-testid="language-select"
                  >
                    {Object.entries(languageNames).map(([key]) => (
                      <MenuItem key={key} value={key} disabled={key === 'bg'} data-testid={key}>{key}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControlLabel
                  control={<Switch checked={pronunciationOn(gameLanguage)}
                                   onChange={(e) => changePronunciation(gameLanguage, e.target.checked)} />}
                  label={t('EnablePronunciation')}
                  data-testid="pronunciation-switch"
                />
              </Case>
              <CaseElse>
                {hasPinyin && <FormControlLabel
                  control={<Checkbox checked={showPinyin} onChange={(e) => setShowPinyin(e.target.checked)} />}
                  label={t('ShowPinyin')} />}
                <FormControl component="fieldset">
                  <FormLabel component="legend">{t('direction')}</FormLabel>
                  <RadioGroup value={direction} onChange={(e) => setDirection(e.target.value as Direction)}>
                    <FormControlLabel value="ltr" control={<Radio />} label={leftToRightLabel} />
                    <FormControlLabel value="rtl" control={<Radio />} label={rightToLeftLabel} />
                  </RadioGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">{t('pronunciation')}</FormLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={pronunciationOn(currentVocabulary?.left_language)}
                        onChange={(e) => {
                          changePronunciation(currentVocabulary?.left_language, e.target.checked)
                        }}
                      />
                    }
                    label={currentVocabulary?.left_language}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={pronunciationOn(currentVocabulary?.right_language)}
                        onChange={(e) =>
                          changePronunciation(currentVocabulary?.right_language, e.target.checked)
                        }
                      />
                    }
                    label={currentVocabulary?.right_language}
                  />
                </FormControl>
                <FormControlLabel
                  control={<Checkbox checked={skipNextTime} onChange={(e) => setSkipNextTime(e.target.checked)} />}
                  label={t('skipSetup')}
                />
              </CaseElse>
            </ValueSwitch>
          </Box>
          <Box display="flex" gap={2}>
            <Button variant={ableToContinue ? 'text' : 'contained'} color="primary" type="submit" name="start"
                    onClick={() => setClickedButton('start')}
                    data-testid="start">{ableToContinue ? t('restart') : t('play')}</Button>
            {ableToContinue &&
              <Button variant="contained" color="primary" type="submit" name="continue"
                      onClick={() => setClickedButton('continue')}
                      data-testid="continue">{t('continue')}</Button>}
          </Box>
        </Stack>
      </Container>
    </form>
  )
}

export default CardsSetup
