import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration' // Import the service worker registration
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { ConfirmProvider } from 'material-ui-confirm'

import { lightTheme, darkTheme } from './theme'
import { ThemeProvider, ThemeContext } from './Providers/ThemeContext'

import './i18n/index.js'
import App from './App'
import store from './Store'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// Note that React.StrictMode double-loads certain containers.
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <ThemeContext.Consumer>
        {({ darkMode }) => (
          <MuiThemeProvider theme={darkMode ? darkTheme : lightTheme}>
            <ConfirmProvider>
              <Provider store={store}>
                <Suspense fallback="Loading...">
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </Suspense>
              </Provider>
            </ConfirmProvider>
            <ToastContainer position="bottom-center" />
          </MuiThemeProvider>
        )}
      </ThemeContext.Consumer>
    </ThemeProvider>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
