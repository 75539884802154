import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from './index'
import { IVocabulary, setCurrentVocabulary } from './VocabulariesSlice'
import { apiRoot } from '../config'

export type Direction = 'ltr' | 'rtl'
export type GameType = 'BlockCardsGame' | 'ImprovedBlockCardsGame' | 'NumbersGame' | 'ZhCharactersGame'

export const startGame = createAsyncThunk(
  'game/startGame',
  async ({ vocabularyId, direction, forceNewGame, gameType, infoLanguage, secretLanguage }: {
    vocabularyId?: number,
    direction: Direction,
    forceNewGame?: boolean,
    gameType: GameType,
    infoLanguage?: string,
    secretLanguage?: string,
  }, { getState, dispatch }) => {
    const rootState = getState() as RootState
    let vocabulary = rootState.vocabularies.currentVocabulary
    if (gameType.endsWith('BlockCardsGame') && !vocabularyId) throw new Error('No vocabulary id')
    let rowIds: Number[] = []
    if (vocabularyId) {
      if (!vocabulary || vocabularyId !== vocabulary.id) {
        const response = await dispatch(setCurrentVocabulary(vocabularyId))
        if (!setCurrentVocabulary.fulfilled.match(response)) throw new Error('Failed to set current vocabulary')
        vocabulary = (getState() as RootState).vocabularies.currentVocabulary as IVocabulary
      }
      rowIds = vocabulary.rows.filter(row => row.left && row.right).map(row => row.id)
    }
    let language = secretLanguage || rootState.preferences.preferences?.lastGameLanguage || 'es'
    if (
      forceNewGame
      || (rootState.game.currentGame?.gameType !== gameType)
      || (gameType.endsWith('BlockCardsGame') && rootState.game.vocabularyId !== vocabulary?.id)
      || !rootState.game.currentGame?.state.current_card?.info) {
      let data: any = {
        direction,
        game_type: gameType
      }
      if (gameType === 'NumbersGame') {
        data.language = language
      }
      else if (gameType.endsWith('BlockCardsGame')) {
        data.row_ids = rowIds
      }
      const response = await axios.post(apiRoot + '/api/cardsgame/', data)
      response.data = { ...response.data, vocabularyId: vocabulary?.id, gameType, language }
      return response.data
    }
  },
)

export const levelUp = createAsyncThunk(
  'game/levelUp',
  async (_, { getState, dispatch }) => {
    const currentGame = (getState() as RootState).game.currentGame
    if (!currentGame) throw new Error('No current game')
    if (currentGame.state.level === (currentGame.state.levels || 0) - 1) {
      dispatch(stopGame())
      return
    }
    const response = await axios.post(apiRoot + `/api/cardsgame/${currentGame.id}/level_up/`, {
      level_up: true,
      token: currentGame.token,
    })
    return response.data
  },
)

export const levelDown = createAsyncThunk(
  'game/levelDown',
  async (_, { getState, dispatch }) => {
    const currentGame = (getState() as RootState).game.currentGame
    if (!currentGame) throw new Error('No current game')
    if (currentGame.state.level === 0) {
      dispatch(stopGame())
      return
    }
    const response = await axios.post(apiRoot + `/api/cardsgame/${currentGame.id}/level_down/`, {
      level_down: true,
      token: currentGame.token,
    })
    return response.data
  },
)

export const getNextCard = createAsyncThunk(
  'game/getNextCard',
  async ({ answer }: { answer: boolean }, { getState, dispatch }) => {
    const state = (getState() as RootState).game
    const currentGame = state.currentGame
    if (!currentGame) throw new Error('No current game')
    if (currentGame.state.next) {
      const nextState = answer ? currentGame.state.next.correct : currentGame.state.next.wrong
      dispatch(gameSlice.actions.setNextState(nextState))
      dispatch(submitAnswer({ answer }))
    } else {
      // If next state doesn't exist, wait for it to arrive
      const response = await dispatch(submitAnswer({ answer }))
      if (!submitAnswer.fulfilled.match(response)) throw new Error('Submit answer failed')
      // ... and then move on to the next state
      const state = (getState() as RootState).game
      const currentGame = state.currentGame as IGame
      if (!currentGame.state.next) throw new Error('No next state')
      const nextState = answer ? currentGame.state.next.correct : currentGame.state.next.wrong
      dispatch(gameSlice.actions.setNextState(nextState))
    }
  },
)

// Async private action to submit answer and get next state
const submitAnswer = createAsyncThunk(
  'game/submitAnswer',
  async ({ answer }: { answer: boolean }, { getState }) => {
    const id = (getState() as RootState).game.currentGame?.id
    if (!id) throw new Error('No current game id')
    const token = (getState() as RootState).game.currentGame?.token as string
    const response = await axios.put(apiRoot + `/api/cardsgame/${id}/`, {
      answer: answer ? 'correct' : 'wrong',
      token,
    })
    return response.data
  },
)

interface IGame {
  id: number
  token: string
  state: IGameState
  gameType: GameType
}

interface IGameState {
  level?: number
  levels?: number
  current_card?: {
    info: string
    secret: string
    row: number  // row id
  }
  message?: string
  progress?: {
    value: number
    text: string
  }
  next?: {
    correct: IGameState
    wrong: IGameState
  }
}

type GameStatus = 'off' | 'idle' | 'loading' | 'playing' | 'failed'

const _adjustLevel = (state: any, action: any) => {
  const { token } = action.payload
  const { current_card, progress, message, next, level, levels } = action.payload
    .state as IGameState
  state.currentGame = {
    ...state.currentGame as IGame,
    token,
    state: { current_card, progress, message, next, level, levels },
  }
  state.status = 'playing'
}

const gameSlice = createSlice({
  name: 'game',
  initialState: {
    vocabularyId: null as number | null,
    currentGame: null as IGame | null,
    status: 'off' as GameStatus,
    error: null as string | null,
  },
  reducers: {
    stopGame: (state) => {
      state.currentGame = null
    },
    // internal actions:

    setNextState: (state, action) => {
      // Set the next state
      const { current_card, progress, message } = action.payload as any
      state.currentGame = {
        ...(state.currentGame as IGame),
        state: { current_card, progress, message },
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startGame.fulfilled, (state, action) => {
        if (!action.payload) {
          console.error('Continue game')
          return
        }
        // Initialize game with setup
        const { id, token, gameType, vocabularyId } = action.payload
        const { current_card, progress, message, next, level, levels } = action.payload
          .state as IGameState
        state.vocabularyId = vocabularyId
        state.currentGame = {
          ...state.currentGame,
          id,
          token,
          gameType,
          state: { current_card, progress, message, next, level, levels },
        }
        state.status = 'playing'
      })
      .addCase(levelUp.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(levelDown.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(levelUp.fulfilled, (state, action) => {
        _adjustLevel(state, action)
      })
      .addCase(levelDown.fulfilled, (state, action) => {
        _adjustLevel(state, action)
      })
      .addCase(submitAnswer.fulfilled, (state, action) => {
        const { token } = action.payload as IGame
        const newState = action.payload.state as IGameState
        const currentGame = state.currentGame as IGame
        currentGame.state = newState
        currentGame.token = token
      })
      .addCase(getNextCard.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getNextCard.fulfilled, (state) => {
        state.status = 'idle'
      })
      .addCase(getNextCard.rejected, (state, action) => {
        state.status = 'failed'
        state.error = (action.error.message || '').toString()
      })
  },
})

export default gameSlice.reducer
export const { stopGame } = gameSlice.actions
