const translations = {
  translations: {
    Required: 'Ce champ est requis',
    'Page Title': 'Vocabulaire',
    'Login Title': 'Connexion',
    'Register Title': 'Inscription',
    Username: 'Nom d\'utilisateur',
    Password: 'Mot de passe',
    'E-mail': 'E-mail',
    Name: 'Nom',
    Login: 'S\'identifier',
    Register: 'S\'inscrire',
    RegisterError: 'Une erreur s\'est produite lors de l\'inscription.',
    RegistrationSuccess: 'Inscription réussie',
    InvalidEmail: 'L\'adresse e-mail est invalide',
    UserAlreadyExists: 'L\'utilisateur existe déjà',
    SignUp: 'S\'inscrire',

    Profile: 'Profil',
    Logout: 'Déconnexion',
    LogoutSuccess: 'Vous avez été déconnecté',
    'Change password': 'Changer le mot de passe',
    'New Password': 'Nouveau mot de passe',
    PasswordRequired: 'Le mot de passe est requis',
    ConfirmNewPassword: 'Confirmer le nouveau mot de passe',
    ConfirmPasswordRequired: 'La confirmation du mot de passe est requise',
    PasswordsDontMatch: 'Les mots de passe ne correspondent pas',
    PasswordSuccess: 'Mot de passe changé avec succès',
    PasswordUpdateError: 'Erreur lors du changement de mot de passe',
    SaveChanges: 'Enregistrer les modifications',
    'Site language': 'Langue du site',
    'Preferred Language': 'Langue préférée',
    LanguageSaved: 'Préférences linguistiques mises à jour avec succès',
    LanguageSaveFailed: 'Erreur de mise à jour des préférences linguistiques.',
    Save: 'Enregistrer',

    'tooltip.Search': 'Recherche',
    'tooltip.Add': 'Ajouter une ligne',
    'tooltip.Delete': 'Supprimer la ligne',
    'tooltip.Learn': 'Apprendre des mots',
    'tooltip.More': 'Plus d\'actions',
    'tooltip.Share': 'Partager le vocabulaire',
    'tooltip.FlipRow': 'Inverser la ligne',
    ShowHidePronunciations: 'Afficher/Masquer les prononciations',
    ShowHideCopyButtons: 'Afficher/Masquer les boutons de copie',
    VocabularyTitlePlaceholder: 'Entrez le titre du vocabulaire',

    'search.placeholder': 'Traduire un mot ou une phrase',
    ErrorLoadingPreferences:
      'Impossible de charger les préférences utilisateur',
    ErrorCreatingVocabulary: 'Impossible de créer le vocabulaire',
    ErrorLoadingVocabularies: 'Impossible de charger les vocabulaires',

    SavingVocabulary:
      'La sauvegarde des modifications du vocabulaire est en cours...',
    VocabularySaved:
      'Les modifications du vocabulaire ont été enregistrées avec succès',

    AddNewVocabulary: 'Ajouter un nouveau vocabulaire',
    UntitledVocabulary: '(Sans titre)',
    Search: 'Recherche',
    ErrorLoadingPage: 'Erreur lors du chargement de la page. Veuillez actualiser.',
    DeleteVocabulary: 'Supprimer le vocabulaire',
    VocabularyDeleted: 'Vocabulaire supprimé',
    ErrorDeletingVocabulary: 'Erreur lors de la suppression du vocabulaire',

    ShareVocabulary: 'Partager le vocabulaire',
    CopyLink: 'Copier le lien',
    OK: 'OK',
    LinkCopiedToClipboard: 'Lien copié dans le presse-papiers',

    setup: 'Configuration',
    direction: 'Direction',
    leftToRight: 'De gauche à droite',
    rightToLeft: 'De droite à gauche',
    pronunciation: 'Prononciation',
    on: 'Activé',
    off: 'Désactivé',
    skipSetup: 'Passer la configuration la prochaine fois',
    play: 'Jouer',

    'cards.splashTitle': 'Jouez aux cartes flash de votre vocabulaire',
    'cards.splashText1': 'Mémorisez les mots de votre vocabulaire à l\'aide de cartes flash. <p>Au début, vous apprendrez des blocs plus petits, comme ceci :</p>',
    'cards.splashText2': 'Ensuite, vous apprendrez des blocs de plus en plus grands :',
    'cards.skipDemo': 'Passer la démo la prochaine fois.',
    'cards.seeDemo': 'voir la démo!',
    'cards.letsStart': 'commençons!',
    'cards.demo': 'démo',
    'cards.demoTitle': 'Démo',
    'cards.step1': 'Étape 1:',
    'cards.demoText1': 'Vous pouvez voir un mot sur une carte. Maintenant, dites à haute voix sa signification!',
    'cards.back': 'retour',
    'cards.next': 'suivant',
    'cards.step2': 'Étape 2:',
    'cards.demoText2s1': 'Appuyez sur ',
    'cards.demoText2s2': ' pour confirmer si vous aviez raison.',
    'cards.step3': 'Étape 3:',
    'cards.demoText3s1': 'Maintenant, si vous aviez raison, appuyez sur ',
    'cards.demoText3s2': '.<br> Si vous pensez que vous aviez tort, appuyez sur ',
    'cards.demoText3s3': '.',
    'cards.step4': 'Étape 4:',
    'cards.demoText4': 'Prochaine carte!<br>Vous recevrez de nouvelles cartes jusqu\'à ce que vous les ayez toutes apprises.<br>Vous pouvez vérifier votre progression en bas de la fenêtre.',

    'cards.finishedTitle': 'Bravo, vous avez fini!',
    'cards.backToHome': 'Retour au vocabulaire',

    'play.Setup': 'Configuration',
    'play.Demo': 'Comment ça marche?',
    'errorSavingPreferences': 'Erreur lors de la sauvegarde des préférences.',

    'cardShow.loading': 'Chargement...',
    'cardShow.errorGettingVocabularyId': 'Impossible de charger les données de la carte. Veuillez actualiser.',
    'cardShow.errorStartingGame': 'Impossible de démarrer le jeu. Veuillez actualiser.',
    'cardShow.errorGettingNextCard': 'Erreur lors de l\'obtention de la carte suivante, veuillez renvoyer votre réponse.',

    'restart': 'Redémarrer',
    'continuer': 'Continuer',
    'CopiedToClipboard': 'Texte copié dans le presse-papier',
    'cardShow.errorSavingRow': 'Erreur lors de l\'enregistrement de la ligne',

    'definitions': 'Définitions',
    'PronunciationFailed': 'On ne peut pas possible de prononcer le mot.',

    '404.title': '404 Non trouvé',
    '404.text': 'La page que vous recherchez n\'existe pas.',
    '404.back': 'Retour à la page d\'accueil',

    'BackToYourVocab': 'Retour à votre vocabulaire',

    NumbersGame: 'Jeu de Nombres',
    ChineseCharactersGame: 'Jeu de Caractères Chinois',
    SelectLanguage: 'Sélectionner la langue',
    ShowPinyin: 'Afficher Pinyin',
    EnablePronunciation: 'Activer la prononciation',

    Reload: 'Recharger',
    Cancel: 'Annuler',

    SessionExpired: 'Votre session a expiré. Veuillez vous reconnecter.',
    ErrorLoadingVocabulary: 'Impossible de charger le vocabulaire',

    WarningPermanentAction: 'Cette action est permanente ! Êtes-vous sûr ?',
    SplitVocabulary: 'Diviser le vocabulaire à la ligne de sélection',
    VocabularySplitInfo: 'Le vocabulaire a été divisé avec succès. Vous pouvez trouver les autres lignes dans "{{title}}".',
    ErrorSplittingVocabulary: 'Erreur lors de la division du vocabulaire',
  },
}
export default translations
