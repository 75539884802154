import { Close, Search } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { performSearch } from '../Store/SearchSlice'
import { useAppDispatch, useAppSelector } from '../hooks'
import LanguageSourceTarget from './LanguageSourceTarget'

export interface ISearch {
  query: string
  sourceLanguage: string
  targetLanguage: string
}

const SearchBar = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const [query, setQuery] = useState('')
  const clearQuery = () => {
    setQuery('')
  }

  const [sourceLanguage, setSourceLanguage] = useState('en')
  const [targetLanguage, setTargetLanguage] = useState('es')

  const handleSearch = (event: any = null) => {
    event?.preventDefault()
    dispatch(
      performSearch({ query, source: sourceLanguage, target: targetLanguage }),
    )
  }
  const currentSearch = useAppSelector((state) => state.search.currentSearch)
  useEffect(() => {
    if (currentSearch) {
      setQuery(currentSearch.query)
      setSourceLanguage(currentSearch.source)
      setTargetLanguage(currentSearch.target)
    }
  }, [currentSearch])

  return (
    <form onSubmit={handleSearch}>
      <TextField
        type="search"
        value={query}
        placeholder={t('search.placeholder')}
        onChange={(e) => setQuery(e.target.value)}
        sx={{ width: '100%', maxWidth: '500px' }}
        InputProps={{
          endAdornment: (
            <>
              {query && (
                <InputAdornment position="end">
                  <IconButton onClick={clearQuery}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              )}
              <InputAdornment position="end">
                <LanguageSourceTarget
                  sourceLanguage={sourceLanguage}
                  targetLanguage={targetLanguage}
                  onChange={({ source, target }) => {
                    setSourceLanguage(source)
                    setTargetLanguage(target)
                    handleSearch()
                  }}
                />
              </InputAdornment>
              <InputAdornment position="end">
                <IconButton type="submit">
                  <Search />
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />
    </form>
  )
}

export default SearchBar
