import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../hooks'
import SearchPanel from '../Components/SearchPanel'
import VocabularyPanel from '../Components/VocabularyPanel'
import { listMyVocabularies, setCurrentVocabulary } from '../Store/VocabulariesSlice'
import './LandingPage.less'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

function LandingPage() {
  const { t } = useTranslation()
  const [searchShown, setSearchShown] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const preferences = useAppSelector((state) => state.preferences.preferences)
  const currentVocabularyId = useAppSelector((state) => state.preferences.preferences?.currentVocabulary)
  const vocabularies = useAppSelector((state) => state.vocabularies.vocabularies)

  useEffect(() => {
    console.log('LandingPage', JSON.stringify(preferences), JSON.stringify(location.search))
    ;(async () => {
      if (!preferences) return
      const searchParams = new URLSearchParams(location.search)
      const v = Number(searchParams.get('v')) || NaN

      console.log('Loading landing page', location.search)
      if (v) {
        const result = await dispatch(setCurrentVocabulary(v))
        if (!setCurrentVocabulary.fulfilled.match(result)) {
          toast.error(t('ErrorLoadingVocabulary'))
        }
        return
      }
      if (currentVocabularyId) {
        navigate(`?v=${currentVocabularyId}`)
        return
      }
      let newId = (vocabularies.length > 0 ? vocabularies[0].id : 0)
      if (!newId) {
        const result = await dispatch(listMyVocabularies())
        if (!listMyVocabularies.fulfilled.match(result)) {
          toast.error(t('ErrorLoadingVocabularies'))
          return
        }
        if(result.payload.length) {
          newId = result.payload[0].id
        }
      }
      if (newId) {
        navigate(`?v=${newId}`)
        return
      }
    })()
  }, [preferences, location.search])


  const currentSearch = useAppSelector((state) => state.search.currentSearch)
  useEffect(() => {
    setSearchShown(currentSearch !== null)
  }, [currentSearch])

  const selectVocabulary = (vocabularyId: number) => {
    navigate(`?v=${vocabularyId}`)
  }

  const handleSearch = ({ query, source, target }: { query: string, source: string, target: string }) => {
    setSearchShown(true)
    console.log('Search', query, source, target)
  }
  return (
    <div style={{ display: 'flex' }} className={searchShown ? 'mobile-search' : ''}>
      <SearchPanel onCloseSearch={() => {
        setSearchShown(false)
      }} />
      <VocabularyPanel onSelectVocabulary={selectVocabulary} onSearch={handleSearch} />
    </div>
  )
}

export default LandingPage