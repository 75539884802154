const translations = {
  translations: {
    Required: 'Ez a mező kötelező',
    'Page Title': 'Szótár',
    'Login Title': 'Bejelentkezés',
    'Register Title': 'Regisztráció',
    Username: 'Felhasználónév',
    Password: 'Jelszó',
    'E-mail': 'E-mail',
    Name: 'Név',
    Login: 'Bejelentkezés',
    Register: 'Regisztráció',
    RegisterError: 'Hiba történt a regisztráció során.',
    RegistrationSuccess: 'Sikeres regisztráció',
    InvalidEmail: 'Az e-mail cím érvénytelen',
    UserAlreadyExists: 'Ez a felhasználó már létezik',
    SignUp: 'Regisztráció',

    Profile: 'Profil',
    Logout: 'Kijelentkezés',
    LogoutSuccess: 'Sikeresen kijelentkeztél',
    'Change password': 'Jelszó megváltoztatása',
    'New Password': 'Új jelszó',
    PasswordRequired: 'Jelszó szükséges',
    ConfirmNewPassword: 'Új jelszó megerősítése',
    ConfirmPasswordRequired: 'Jelszó megerősítése szükséges',
    PasswordsDontMatch: 'A jelszavak nem egyeznek',
    PasswordSuccess: 'A jelszó sikeresen megváltozott',
    PasswordUpdateError: 'Hiba történt a jelszó frissítése során',
    SaveChanges: 'Változtatások mentése',
    'Site language': 'Oldal nyelve',
    'Preferred Language': 'Preferált nyelv',
    LanguageSaved: 'Nyelvi beállítások sikeresen frissítve',
    LanguageSaveFailed: 'Hiba történt a nyelvi beállítások frissítése során',
    Save: 'Mentés',

    'tooltip.Search': 'Keresés',
    'tooltip.Add': 'Sor hozzáadása',
    'tooltip.Delete': 'Sor törlése',
    'tooltip.Learn': 'Szavak tanulása',
    'tooltip.More': 'További műveletek',
    'tooltip.Share': 'Szótár megosztása',
    'tooltip.FlipRow': 'Sor megfordítása',
    ShowHidePronunciations: 'Kiejtések megjelenítése/elrejtése',
    ShowHideCopyButtons: 'Másolás gombok megjelenítése/elrejtése',
    VocabularyTitlePlaceholder: 'Add meg a szótár címét',

    'search.placeholder': 'Szó vagy kifejezés fordítása',
    ErrorLoadingPreferences:
      'Nem sikerült betölteni a felhasználói beállításokat',
    ErrorCreatingVocabulary: 'Nem sikerült létrehozni a szótárat',
    ErrorLoadingVocabularies: 'Nem sikerült betölteni a szótárakat',

    SavingVocabulary: 'A szótár módosításainak mentése folyamatban...',
    VocabularySaved: 'A szótár módosításai sikeresen mentve',

    AddNewVocabulary: 'Új szótár hozzáadása',
    UntitledVocabulary: '(Cím nélkül)',
    Search: 'Keresés',
    ErrorLoadingPage: 'Hiba történt az oldal betöltésekor. Kérjük, frissítse az oldalt.',
    DeleteVocabulary: 'Szótár törlése',
    VocabularyDeleted: 'Szótár törölve',
    ErrorDeletingVocabulary: 'Hiba történt a szótár törlésekor',

    ShareVocabulary: 'Szótár megosztása',
    CopyLink: 'Link másolása',
    OK: 'OK',
    LinkCopiedToClipboard: 'Link másolva a vágólapra',

    setup: 'Beállítás',
    direction: 'Irány',
    leftToRight: 'Balról jobbra',
    rightToLeft: 'Jobbról balra',
    pronunciation: 'Kiejtés',
    on: 'Bekapcsolva',
    off: 'Kikapcsolva',
    skipSetup: 'Beállítások kihagyása a következő alkalommal',
    play: 'Játék indítása',

    'cards.splashTitle': 'Szókártyás kikérdező',
    'cards.splashText1': 'Tanuld meg a szavaidat szókártyákkal. <p>Először kisebb blokkokat tanulsz, mint például ez:</p>',
    'cards.splashText2': 'Aztán egyre nagyobb és nagyobb blokkokat tanulsz:',
    'cards.skipDemo': 'Demó kihagyása legközelebb.',
    'cards.seeDemo': 'nézzük a demót!',
    'cards.letsStart': 'kezdjük!',
    'cards.demo': 'demó',
    'cards.demoTitle': 'Demó',
    'cards.step1': '1. lépés:',
    'cards.demoText1': 'Láthatsz egy szót a kártyán. Most mondd ki hangosan a jelentését!',
    'cards.back': 'vissza',
    'cards.next': 'következő',
    'cards.step2': '2. lépés:',
    'cards.demoText2s1': 'Nyomd meg a ',
    'cards.demoText2s2': ' gombot, ha helyesen válaszoltál.',
    'cards.step3': '3. lépés:',
    'cards.demoText3s1': 'Most, ha helyesen válaszoltál, nyomd meg a ',
    'cards.demoText3s2': ' gombot.<br> Ha úgy gondolod, hogy hibáztál, nyomd meg a ',
    'cards.demoText3s3': ' gombot.',
    'cards.step4': '4. lépés:',
    'cards.demoText4': 'Következő kártya!<br>Újabb és újabb kártyákat kapsz, amíg mindet meg nem tanulod.<br>Ellenőrizheted a haladásodat az ablak alján.',

    'cards.finishedTitle': 'Gratulálok, végeztél!',
    'cards.backToHome': 'Vissza a szótárhoz',

    'play.Setup': 'Beállítás',
    'play.Demo': 'Hogyan működik?',
    'errorSavingPreferences': 'Hiba a beállítások mentésekor.',

    'cardShow.loading': 'Betöltés...',
    'cardShow.errorGettingVocabularyId': 'Nem sikerült betölteni a kártya adatait. Frissíts kérlek.',
    'cardShow.errorStartingGame': 'Nem sikerült elindítani a játékot. Frissíts kérlek.',
    'cardShow.errorGettingNextCard': 'Hiba a következő kártya lekérésekor. Küldd el újra a választ.',

    'restart': 'Újraindítás',
    'continue': 'Folytatás',
    'CopiedToClipboard': 'Szöveg a vágólapra másolva',
    'cardShow.errorSavingRow': 'Hiba a sor mentése során',

    'definitions': 'Definíciók',
    'PronunciationFailed': 'A szót nem tudtuk kiejteni.',

    '404.title': '404 Nem található',
    '404.text': 'A keresett oldal nem létezik.',
    '404.back': 'Vissza a kezdőoldalra',

    'BackToYourVocab': 'Vissza a szótáradhoz',

    NumbersGame: 'Számjáték',
    ChineseCharactersGame: 'Kínai Karakter Játék',
    SelectLanguage: 'Nyelv kiválasztása',
    ShowPinyin: 'Pinyin megjelenítése',
    EnablePronunciation: 'Kiejtések engedélyezése',

    Reload: 'Újratöltés',
    Cancel: 'Mégse',

    SessionExpired: 'A munkamenet lejárt. Kérjük, jelentkezz be újra.',
    ErrorLoadingVocabulary: 'A szókincs betöltése nem sikerült.',

    WarningPermanentAction: 'Ez a művelet végleges! Biztos vagy benne?',
    SplitVocabulary: 'Szótár kettévágása a kijelölt sorban',
    VocabularySplitInfo: 'A szószedet sikeresen felosztásra került. A többi sort a(z) "{{title}}" szótárban találhatod.',
    ErrorSplittingVocabulary: 'Hiba történt a szószedet felosztása közben',
  },
}
export default translations
