import { useMemo, useRef, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { useAppSelector } from '../hooks'
import { Box, Button, IconButton, Menu, MenuItem, MenuList } from '@mui/material'
import { Menu as MenuIcon, DarkMode, LightMode } from '@mui/icons-material'

import { ThemeContext } from '../Providers/ThemeContext'

import './Navbar.less'

function Navbar() {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn)

  const { t } = useTranslation()
  const username = useAppSelector((state) => state.auth.user?.username)
  const ref = useRef<HTMLButtonElement>(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const menuItems = useMemo(() => {
    if (!isLoggedIn) {
      return [
        { label: t('Login'), to: '/login' },
        { label: t('SignUp'), to: '/register' },
      ]
    } else {
      return [
        { label: username, to: '/profile' },
        { label: t('NumbersGame'), to: '/cards/numbers' },
        { label: t('ChineseCharactersGame'), to: '/cards/hanzi' },
      ]
    }
  }, [t, isLoggedIn, username])

  const { darkMode, toggleDarkMode } = useContext(ThemeContext)

  return (
    <div>
      <AppBar position="static" elevation={0} sx={{ paddingTop: 'env(safe-area-inset-top)' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <Link className="navbar-brand ng-binding" to="/">
              <img src="/navbar-logo.png" alt="logo" height={40} />
              Vocabulary&nbsp;
              <div className="topbrand">PeopleNinja</div>
            </Link>
          </Box>
          <IconButton color="inherit" onClick={toggleDarkMode} data-testid="dark-switch">
            {darkMode ? <LightMode /> : <DarkMode />}
          </IconButton>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {menuItems.map((item, index) => {
              return (
                <Button
                  color="inherit"
                  key={index}
                  component={Link}
                  to={item.to}
                  onClick={() => setMenuOpen(false)}
                >
                  {item.label}
                </Button>
              )
            })}
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              color="inherit"
              size="large"
              edge="start"
              aria-haspopup="true"
              aria-controls="menu"
              onClick={() => setMenuOpen(true)}
              ref={ref}
              sx={{ marginLeft: '0px' }}
              data-testid="menu"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
              sx={{ display: { xs: 'flex', md: 'none' }}}
              anchorEl={ref.current}
            >
              <MenuList role="menu" sx={{padding: 0}}>
                {menuItems.map((item, index) => {
                  return (
                    <MenuItem
                      role="menuitem"
                      key={index}
                      component={Link}
                      to={item.to}
                      onClick={() => setMenuOpen(false)}
                    >
                      {item.label}
                    </MenuItem>
                  )
                })}
              </MenuList>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Navbar
