import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useAppDispatch, useAppSelector } from '../hooks'
import { logOut } from '../Store/AuthSlice'
import { useTranslation } from 'react-i18next'
import { updatePreferences } from '../Store/PreferencesSlice'

function Profile() {
  const { t } = useTranslation()

  const preferences = useAppSelector((state) => state.preferences.preferences)
  const [selectedLanguage, setSelectedLanguage] = useState(
    preferences?.language || 'en',
  )

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const handleLogout = async () => {
    await dispatch(logOut())
    toast.success(t('LogoutSuccess'))
    navigate('/login')
  }

  const handleLanguageChange = (event: { target: { value: any } }) => {
    setSelectedLanguage(event.target.value)
  }
  const handleSaveLanguage = async () => {
    const result = await dispatch(
      updatePreferences({ language: selectedLanguage }),
    )
    if (updatePreferences.fulfilled.match(result)) {
      toast.success(t('LanguageSaved'))
      navigate('/')
    } else {
      toast.error(t('LanguageSaveFailed'))
    }
  }

  return (
    <Container maxWidth="xs">
      <h1>{t('Profile')}</h1>

      <Button onClick={handleLogout} data-testid="logout">{t('Logout')}</Button>

      <Box
        component="form"
        sx={{
          mt: 4,
          mb: 4,
        }}
        autoComplete="off"
      >
        <h2>{t('Site language')}</h2>

        <FormControl fullWidth margin="normal">
          <InputLabel id="language-label">{t('Preferred Language')}</InputLabel>
          <Select
            labelId="language-label"
            value={selectedLanguage}
            onChange={handleLanguageChange}
            data-testid="language-select"
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="de" data-testid="language-de">Deutsch</MenuItem>
            <MenuItem value="fr">Français</MenuItem>
            <MenuItem value="es">Español</MenuItem>
            <MenuItem value="hu">Magyar</MenuItem>
          </Select>
        </FormControl>
        <FormControl margin="normal">
          <Button variant="contained" onClick={handleSaveLanguage} data-testid="save">
            {t('Save')}
          </Button>
        </FormControl>
      </Box>
      <Button onClick={() => navigate("/")}>
        {t('BackToYourVocab')}
      </Button>
    </Container>
  )
}

export default Profile
