import { IconButton } from '@mui/material'
import { ContentCopy, VolumeDown } from '@mui/icons-material'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../hooks'
import { pronounce } from '../Store/PronounceSlice'
import { copyToClipboard } from '../Utilities/tools'

interface VocabularyToolsProps {
  value: string
  displayTools: string
  language: string
}

export type DisplayTool = 'pronounce' | 'copy' | ''

const VocabularyTools = ({ value, displayTools, language }: VocabularyToolsProps) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const [lastPronounced, setLastPronounced] = useState('')
  const handlePronounce = async (text: string, language: string) => {
    const slow = (text === lastPronounced)
    const result = await dispatch(pronounce({ text, language, slow }))
    if (!pronounce.fulfilled.match(result)) {
      toast.error(t('PronunciationFailed'))
    }
    setLastPronounced((slow) ? '' : text)
  }

  const handleCopyToClipboard = (text: string) => {
    copyToClipboard(text).then(() => {
      toast.success(t('CopiedToClipboard'))
    })
  }

  return (<div className="tools" tabIndex={-1}>
    {value && (
      <>
        {displayTools === 'pronounce' && (
          <IconButton
            tabIndex={-1}
            onClick={async () => {
              await handlePronounce(value, language)
            }}
          >
            <VolumeDown />
          </IconButton>
        )}

        {displayTools === 'copy' && (
          <IconButton
            tabIndex={-1}
            onClick={() => {
              handleCopyToClipboard(value)
            }}
          >
            <ContentCopy />
          </IconButton>
        )}
      </>
    )}
  </div>)
}

export default VocabularyTools