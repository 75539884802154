import React from 'react'
import { Select, MenuItem, SelectChangeEvent } from '@mui/material'
import { languageNames } from '../config'

type LanguageDropdownProps = {
  className?: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  displayFullNames?: boolean;
};

const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
                                                             className,
                                                             value,
                                                             onChange,
                                                             displayFullNames,
                                                           }) => {
  const languages = Object.keys(languageNames)

  return (
    <Select
      className={className}
      value={value}
      onChange={onChange}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      }}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      }}
    >
      {languages.map((lang) => (
        <MenuItem key={lang} value={lang}>
          {displayFullNames ? `${languageNames[lang]} (${lang})` : lang}
        </MenuItem>
      ))}
    </Select>
  )
}

export default LanguageDropdown
