import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Vocabulary from './Vocabulary'
import { Button } from '@mui/material'

import './CardsDemo.less'
import { useAppSelector } from '../hooks'
import { Close, Done, KeyboardArrowDown } from '@mui/icons-material'

interface CardsDemoProps {
  onContinue: () => void
  onSetSkipDemo: (value: boolean) => void
}

const CardsDemo = ({ onContinue, onSetSkipDemo }: CardsDemoProps) => {
  const { t } = useTranslation()
  const [slide, setSlide] = useState('start')
  const startSlide = useAppSelector((state) => state.preferences.preferences?.cardStartSlide || 'demo')
  const skipDemo = useMemo(() => startSlide !== 'demo', [startSlide])

  const demoVocab1 = {
    id: 0,
    title: '',
    owner: 0,
    left_language: 'en',
    right_language: 'es',
    created: new Date(),
    rows: [['Apfel', 'apple'], ['Banane', 'banana']].map(([left, right]) => {
      return { id: 0, left, right, left_score: 0, right_score: 0 }
    }),
  }
  const demoVocab2 = {
    id: 0,
    title: '',
    owner: 0,
    left_language: 'de',
    right_language: 'en',
    created: new Date(),
    rows: [['Apfel', 'apple'], ['Banane', 'banana'], ['Kirsche', 'cherry'], ['Dattel', 'date']].map(
      ([left, right]) => {
        return { id: 0, left, right, left_score: 0, right_score: 0 }
      },
    ),
  }

  return (
    <div className="cards-demo">
      {slide === 'start' && (
        <div>
          <h1>{t('cards.splashTitle')}</h1>
          <span dangerouslySetInnerHTML={{ __html: t('cards.splashText1') }} />
          {/* Replace 'demoVocab1' and 'demoVocab2' with your actual Vocabulary components */}
          <div className="vocabulary-wrap">
            <Vocabulary vocabulary={demoVocab1} readOnly />
          </div>
          {t('cards.splashText2')}
          <div className="vocabulary-wrap">
            <Vocabulary vocabulary={demoVocab2} readOnly />
          </div>
          <input type="checkbox" onChange={() => onSetSkipDemo(!skipDemo)} checked={skipDemo} />
          {t('cards.skipDemo')}
          <div className="pager">
            <Button onClick={() => onContinue()}>{t('cards.letsStart')}</Button>
            <Button variant="contained" onClick={() => setSlide('demo-1')}>{t('cards.demo')}</Button>
          </div>
        </div>
      )}

      {slide === 'demo-1' && (
        <div>
          <h1>{t('cards.demoTitle')}</h1>
          <div className="demo-figure">
            <img src="/assets/images/demo-1.png" alt="Demo 1" />
          </div>
          <b>{t('cards.step1')}</b>&nbsp;{t('cards.demoText1')}
          <div className="pager">
            <Button onClick={() => setSlide('start')}>{t('cards.back')}</Button>
            <Button variant="contained" onClick={() => setSlide('demo-2')}>{t('cards.next')}</Button>
          </div>
        </div>
      )}

      {slide === 'demo-2' && (
        <div>
          <h1>{t('cards.demoTitle')}</h1>
          <div className="demo-figure">
            <img src="/assets/images/demo-2.png" alt="Demo 2" />
          </div>
          <b>{t('cards.step2')}</b>&nbsp;{t('cards.demoText2s1')}
          <Button className="action small" onClick={() => setSlide('demo-3')}><KeyboardArrowDown /></Button>
          {t('cards.demoText2s2')}
          <div className="pager">
            <Button onClick={() => setSlide('demo-1')}>{t('cards.back')}</Button>
            <Button variant="contained" onClick={() => setSlide('demo-3')}>{t('cards.next')}</Button>
          </div>
        </div>
      )}

      {slide === 'demo-3' && (
        <div>
          <h1>{t('cards.demoTitle')}</h1>
          <div className="demo-figure">
            <img src="/assets/images/demo-3.png" alt="Demo 3" />
          </div>
          <b>{t('cards.step3')}</b>&nbsp;{t('cards.demoText3s1')}
          <Button className="action small" variant="contained" color="success"
                  onClick={() => setSlide('demo-4')}><Done /></Button>
          <span dangerouslySetInnerHTML={{ __html: t('cards.demoText3s2') }} />
          <Button className="action small" variant="contained" color="error"
                  onClick={() => setSlide('demo-4')}><Close /></Button>
          {t('cards.demoText3s3')}
          <div className="pager">
            <Button onClick={() => setSlide('demo-2')}>{t('cards.back')}</Button>
            <Button variant="contained" onClick={() => setSlide('demo-4')}>{t('cards.next')}</Button>
          </div>
        </div>
      )}

      {slide === 'demo-4' && (
        <div>
          <h1>{t('cards.demoTitle')}</h1>
          <div className="demo-figure">
            <img src="/assets/images/demo-4.png" alt="Demo 4" />
          </div>
          <b>{t('cards.step4')}</b>
          <div dangerouslySetInnerHTML={{ __html: t('cards.demoText4') }} />
          <div className="pager">
            <Button onClick={() => setSlide('demo-3')}>{t('cards.back')}</Button>
            <Button variant="contained" onClick={() => onContinue()}>{t('cards.letsStart')}</Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default CardsDemo