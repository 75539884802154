/**
 * ValueSwitch is like a traditional switch block.
 * It takes a test value and then looks for a Case component with a matching value.
 *
 * For a more standard switch block, use reach-if's Switch component.
 */

import React from 'react'

type ValueSwitchProps = {
  test: string;
  children: React.ReactNode;
};

type CaseProps = {
  value: string;
  children: React.ReactNode;
};

// ValueSwitch as opposed to react-if's Switch
export const ValueSwitch: React.FC<ValueSwitchProps> = ({ test, children }) => {
  let match: React.ReactNode = null
  React.Children.forEach(children, child => {
    if (!React.isValidElement(child)) {
      return
    }
    if (child.props.value === test || (match === null && !('value' in child.props))) {
      match = child
    }
  })
  return match
}
export const Case: React.FC<CaseProps> = (props) => {
  return <>{props.children}</>
}

export const CaseElse: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>
}
