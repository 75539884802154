import { isEqual } from 'lodash'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import CardsSetup, { CardSetupData } from '../Components/CardsSetup'
import { useAppDispatch, useAppSelector } from '../hooks'
import { Slide, updatePreferences } from '../Store/PreferencesSlice'
import CardsPlay, { PlayPreferences } from '../Components/CardsPlay'
import CardsDemo from '../Components/CardsDemo'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'
import { Case, ValueSwitch } from '../Utilities/Switch'

import './CardsPage.less'
import { toast } from 'react-toastify'
import { GameType, stopGame } from '../Store/GameSlice'
import { setCurrentVocabulary } from '../Store/VocabulariesSlice'

let adjustSlide = true

type CardsPageParameters = {
  gameType: GameType
}

const CardsPage: React.FC<CardsPageParameters> = ({ gameType }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const preferences = useAppSelector((state) => state.preferences.preferences)
  const currentGame = useAppSelector((state) => state.game.currentGame)
  const currentVocabulary = useAppSelector((state) => state.vocabularies.currentVocabulary)

  const [playPreferences, setPlayPreferences] = React.useState<PlayPreferences>({
    direction: 'ltr',
    forceNewGame: false,
    gameType,
  })

  const [slide, setSlide] = React.useState<Slide>('demo')
  const skipDemo = useAppSelector((state) =>
    (state.preferences.preferences?.cardStartSlide || 'demo') !== 'demo',
  )

  const goToInitialSlide = () => {
    if (preferences === null) return
    if (gameType === 'NumbersGame') {
      setSlide('setup')
      return
    } else if (gameType === 'ZhCharactersGame') {
      setSlide('play')
      return
    }
    const searchParams = new URLSearchParams(location.search)
    const v = Number(searchParams.get('v')) || NaN
    if (v && currentVocabulary?.id !== v) {
      (async () => {
        const result = await dispatch(setCurrentVocabulary(v))
        if (!setCurrentVocabulary.fulfilled.match(result)) {
          throw new Error('ErrorLoadingVocabulary')
        }
      })().catch(() => {
        toast.error(t('ErrorLoadingVocabulary'))
        setSlide('error')
        return
      })
    }
    setSlide(preferences.cardStartSlide || 'demo')
  }

  useEffect(() => {
    if (preferences === null) return
    if (!adjustSlide) return
    if (currentGame && currentGame.gameType !== gameType) {
      (async () => {
        await dispatch(stopGame())
      })()
    }
    goToInitialSlide()
  }, [preferences, gameType])

  const updatePreferencesWithoutChangingSlide = async (preferencesToSave: any) => {
    adjustSlide = false
    const response = await dispatch(updatePreferences(preferencesToSave))
    adjustSlide = true
    return response
  }

  const handleOnSubmit = async (data: CardSetupData) => {
    let preferencesToSave: any = {
      cardDirection: data.direction,
      cardPronunciations: data.pronunciations,
    }
    if (data.gameType === 'NumbersGame') {
      preferencesToSave['lastGameLanguage'] = data.secretLanguage
    }
    if (data.showPinyin !== undefined) {
      preferencesToSave['cardShowPinyin'] = data.showPinyin
    }
    if (data.skipNextTime && preferences?.cardStartSlide !== 'play') {
      preferencesToSave['cardStartSlide'] = 'play'
    } else if (!data.skipNextTime && preferences?.cardStartSlide === 'play') {
      preferencesToSave['cardStartSlide'] = 'setup'
    }
    if (!isEqual(data.pronunciations, preferences?.cardPronunciations)) {
      preferencesToSave['cardPronunciations'] = data.pronunciations
    }
    const response = await updatePreferencesWithoutChangingSlide(preferencesToSave)
    if (!updatePreferences.fulfilled.match(response)) {
      toast.error(t('errorSavingPreferences'))
      setSlide('error')
      return
    }
    const { direction, showPinyin, forceNewGame, gameType, infoLanguage, secretLanguage } = data
    setPlayPreferences({
      direction,
      showPinyin,
      forceNewGame,
      gameType,
      infoLanguage,
      secretLanguage,
    })
    setSlide('play')
  }

  const handleSetSkipDemo = async (value: boolean) => {
    console.log('handleSetSkipDemo', value, skipDemo)
    if (value === skipDemo) return
    await updatePreferencesWithoutChangingSlide({ cardStartSlide: value ? 'setup' : 'demo' })
  }

  const [finalMessage, setFinalMessage] = React.useState<string>('')
  const handleOnFinished = (message: string) => {
    if (!currentGame) return
    setSlide('finished')
    setFinalMessage(message)
  }

  return (
    <Box display="flex" justifyContent="center" sx={{ padding: [1, 4] }} className="cards-page">
      {preferences && (<ValueSwitch test={slide}>
        <Case value="demo"><CardsDemo onContinue={() => setSlide('setup')} onSetSkipDemo={handleSetSkipDemo} /></Case>
        <Case value="setup"><CardsSetup onSubmit={handleOnSubmit} gameType={gameType} /></Case>
        <Case value="play">
          <CardsPlay
            preferences={playPreferences}
            onDemo={() => setSlide('demo')}
            onSetup={() => setSlide('setup')}
            onFinished={handleOnFinished} />
        </Case>
        <Case value="finished">
          <div>
            <h2>{t('cards.finishedTitle')}</h2>
            <p>{finalMessage}</p>
            <Box display="flex" justifyContent="center" sx={{ padding: [1, 4] }}>
              <Button onClick={() => navigate('/')} data-testid="finish">{t('cards.backToHome')}</Button>
            </Box>
          </div>
        </Case>
        <Case value="error">
          <Box display="flex" justifyContent="center" sx={{ padding: [1, 4] }}>
            <Button color="error" onClick={() => navigate(0)}>{t('Reload')}</Button>
          </Box>
        </Case>
      </ValueSwitch>)}
    </Box>
  )
}

export default CardsPage
