import React, { useState } from 'react';

export const ThemeContext = React.createContext({
  darkMode: false,
  toggleDarkMode: () => {},
});

const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
if (prefersDark) {
  // The user has a system-wide preference for dark mode
  document.documentElement.setAttribute('data-theme', 'dark')
}

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [darkMode, setDarkMode] = useState(prefersDark);

  const toggleDarkMode = () => {
    const newMode = !darkMode
    setDarkMode(newMode)
    if (newMode) {
      document.documentElement.setAttribute('data-theme', 'dark')
    } else {
      document.documentElement.removeAttribute('data-theme')
    }
  }

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};
