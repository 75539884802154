import DOMPurify from 'dompurify'
import { Clipboard } from '@capacitor/clipboard'

import { server } from '../config'
import { PlayAudioFromUrl } from 'capacitor-play-audio-from-url'

let audioQueue: string[] = []
let audioPlaying = false

export const enqueueAudio = async (audioPath: string) => {
  const url = new URL(audioPath)
  audioPath = `${server}${url.pathname}`

  // Add the MediaObject to the queue
  audioQueue.push(audioPath)

  if (!audioPlaying) {
    // If no audio is currently playing, start playing the next audio
    await playNextAudio()
  }
}


const playAudio = async (audioPath: string) => {
  const audioFileName = audioPath.split('/').pop()
  if (!audioFileName) {
    return
  }
  try {
    await PlayAudioFromUrl.play({ url: audioPath })
  } catch (error) {
    return
  }
}
const playNextAudio = async () => {
  // If the queue is empty, do nothing
  if (audioQueue.length === 0) {
    audioPlaying = false
    return
  }

  // Get the next MediaObject from the queue
  const nextAudio = audioQueue.shift()
  if (!nextAudio) return

  // Play the audio
  audioPlaying = true
  await playAudio(nextAudio)
  await playNextAudio()
  audioPlaying = false
}
export const copyToClipboard = (text: string) => {
  return Clipboard.write({ string: text })
}

export const text_from_html = (html: string) => {
  // gets rid of html tags, converts &nbsp; and other html entities.
  const div = document.createElement('div')
  div.innerHTML = html
  return div.textContent || div.innerText || ''
}

export const pronounce_builtin = (text: string, language: string, slow?: boolean) => {
  text = text_from_html(text)

  // Support for adding pronunciation e.g. faux /fó/ (we don't want to pronounce that part)
  const textToPronounce = text.split(' /')[0]


  const utterance = new SpeechSynthesisUtterance(textToPronounce)
  utterance.lang = language
  utterance.rate = slow ? 0.45 : 0.9
  console.log('Pronouncing', textToPronounce, language, slow)
  window.speechSynthesis.speak(utterance)
}

const purifyConfig = { ALLOWED_TAGS: ['em', 'br', 'img', 'table', 'tr', 'td', 'wbr'] }

export const sanitizeCell = (cell: string) => {
  return DOMPurify.sanitize(cell, purifyConfig)
}

export const isDesktop = () => {
  return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}