import { useEffect, useState } from 'react';

export const useAutoPronounce = (pronunciationPreference: boolean, pronounce: () => Promise<any>) => {
  const [performPronounce, setPerformPronounce] = useState(false);
  const [performingPronounce, setPerformingPronounce] = useState(false);

  const autoPronounce = () => {
    if (pronunciationPreference) {
      setPerformPronounce(true);
    }
  };

  useEffect(() => {
    if (performPronounce) {
      if (!performingPronounce) {
        setPerformingPronounce(true);
        pronounce().then(() => setPerformingPronounce(false))
      }
      setPerformPronounce(false);
    }
  }, [performPronounce]);

  return autoPronounce;
};