const translations = {
  translations: {
    Required: 'Este campo es requerido',
    'Page Title': 'Vocabulario',
    'Login Title': 'Inicio de sesión',
    'Register Title': 'Registro',
    Username: 'Nombre de usuario',
    Password: 'Contraseña',
    'E-mail': 'Correo electrónico',
    Name: 'Nombre',
    Login: 'Iniciar sesión',
    Register: 'Registrarse',
    RegisterError: 'Se produjo un error durante el registro.',
    RegistrationSuccess: 'Registro exitoso',
    InvalidEmail: 'La dirección de correo electrónico no es válida',
    UserAlreadyExists: 'El usuario ya existe',
    SignUp: 'Registrarse',

    Profile: 'Perfil',
    Logout: 'Cerrar sesión',
    LogoutSuccess: 'Has cerrado sesión',
    'Change password': 'Cambiar contraseña',
    'New Password': 'Nueva contraseña',
    PasswordRequired: 'Se requiere contraseña',
    ConfirmNewPassword: 'Confirmar nueva contraseña',
    ConfirmPasswordRequired: 'Se requiere confirmar la contraseña',
    PasswordsDontMatch: 'Las contraseñas no coinciden',
    PasswordSuccess: 'Contraseña cambiada con éxito',
    PasswordUpdateError: 'Error al cambiar la contraseña',
    SaveChanges: 'Guardar cambios',
    'Site language': 'Idioma del sitio',
    'Preferred Language': 'Idioma preferido',
    LanguageSaved: 'Preferencias de idioma actualizada con éxito',
    LanguageSaveFailed: 'Error al actualizar las preferencias de idioma',
    Save: 'Guardar',

    'tooltip.Search': 'Buscar',
    'tooltip.Add': 'Agregar fila',
    'tooltip.Delete': 'Eliminar fila',
    'tooltip.Learn': 'Aprender palabras',
    'tooltip.More': 'Más acciones',
    'tooltip.Share': 'Compartir vocabulario',
    'tooltip.FlipRow': 'Invertir fila',
    ShowHidePronunciations: 'Mostrar/Ocultar pronunciaciones',
    ShowHideCopyButtons: 'Mostrar/Ocultar botones de copia',
    VocabularyTitlePlaceholder: 'Introduzca el título del vocabulario',

    'search.placeholder': 'Traducir palabra o frase',
    ErrorLoadingPreferences:
      'No se pudieron cargar las preferencias del usuario',
    ErrorCreatingVocabulary: 'No se pudo crear el vocabulario',
    ErrorLoadingVocabularies: 'No se pudieron cargar los vocabularios',

    SavingVocabulary: 'Guardando cambios en el vocabulario...',
    VocabularySaved: 'Cambios en el vocabulario guardados con éxito',

    AddNewVocabulary: 'Agregar nuevo vocabulario',
    UntitledVocabulary: '(Sin título)',
    Search: 'Buscar',
    ErrorLoadingPage: 'Error al cargar la página. Por favor, actualiza.',
    DeleteVocabulary: 'Eliminar vocabulario',
    VocabularyDeleted: 'Vocabulario eliminado',
    ErrorDeletingVocabulary: 'Error al eliminar el vocabulario',

    ShareVocabulary: 'Compartir vocabulario',
    CopyLink: 'Copiar enlace',
    OK: 'OK',
    LinkCopiedToClipboard: 'Enlace copiado al portapapeles',

    setup: 'Configuración',
    direction: 'Dirección',
    leftToRight: 'De izquierda a derecha',
    rightToLeft: 'De derecha a izquierda',
    pronunciation: 'Pronunciación',
    on: 'Encendido',
    off: 'Apagado',
    skipSetup: 'Omitir configuración la próxima vez',
    play: 'Jugar',

    'cards.splashTitle': 'Cartas virtuales de tu vocabulario',
    'cards.splashText1': 'Memoriza palabras en tu vocabulario usando cartas virtuales. <p>Ahora lee los pequeños recuadros, que figuran debajo:</p>',
    'cards.splashText2': 'Ahora tu leerás más y más recuadros:',
    'cards.skipDemo': 'Saltar demo.',
    'cards.seeDemo': 'Ver demo',
    'cards.letsStart': 'Empieza a jugar',
    'cards.demo': 'demo',
    'cards.demoTitle': 'Demo',
    'cards.step1': 'Paso 1:',
    'cards.demoText1': 'Tú puedes ver una palabra en la carta virtual. ¡Ahora dilo en alto el significado!',
    'cards.back': 'Atras',
    'cards.next': 'Siguiente',
    'cards.step2': 'Paso 2:',
    'cards.demoText2s1': 'Click ',
    'cards.demoText2s2': ' para confirmar.',
    'cards.step3': 'Paso 3:',
    'cards.demoText3s1': 'Ahora si estas seguro, haz click',
    'cards.demoText3s2': '.<br> Si piensas que estas equivocado, haz click ',
    'cards.demoText3s3': '.',
    'cards.step4': 'Paso 4:',
    'cards.demoText4': '¡Siguiente carta!<br>Tú recibirás una y otra vez nuevas cartas, hasta que leas todas.<br>Puedes revisar tú avance en la barra de progreso.',

    'cards.finishedTitle': '¡Felicidades, has terminado!',
    'cards.backToHome': 'Volver al vocabulario',

    'play.Setup': 'Configuración',
    'play.Demo': '¿Cómo funciona?',
    'errorSavingPreferences': 'Error al guardar las preferencias.',

    'cardShow.loading': 'Cargando...',
    'cardShow.errorGettingVocabularyId': 'No se pudo cargar los datos de la tarjeta. Por favor, actualiza.',
    'cardShow.errorStartingGame': 'No se pudo iniciar el juego. Por favor, actualiza.',
    'cardShow.errorGettingNextCard': 'Error al obtener la siguiente tarjeta, por favor envía tu respuesta de nuevo.',

    'restart': 'Reiniciar',
    'continue': 'Continuar',
    'CopiedToClipboard': 'Texto copiado al portapapeles',
    'cardShow.errorSavingRow': 'Error al guardar fila',

    'definitions': 'Definiciones',
    'PronunciationFailed': 'No se pudo pronunciar la palabra.',

    '404.title': '404 No encontrado',
    '404.text': 'La página que buscas no existe.',
    '404.back': 'Volver a la página de inicio',

    'BackToYourVocab': 'Volver a tu vocabulario',

    NumbersGame: 'Juego de Números',
    ChineseCharactersGame: 'Juego de Caracteres Chinos',
    SelectLanguage: 'Seleccionar idioma',
    ShowPinyin: 'Mostrar Pinyin',
    EnablePronunciation: 'Activar pronunciación',

    Reload: 'Recargar',
    Cancel: 'Cancelar',

    SessionExpired: 'Tu sesión ha expirado. Por favor, inicia sesión de nuevo.',
    ErrorLoadingVocabulary: 'Error al cargar el vocabulario',

    WarningPermanentAction: '¡Esta acción es permanente! ¿Estás seguro?',
    SplitVocabulary: 'Dividir el vocabulario en la línea de selección',
    VocabularySplitInfo: 'El vocabulario se dividió con éxito. Puedes encontrar las otras filas en "{{title}}".',
    ErrorSplittingVocabulary: 'Error al dividir el vocabulario',
  },
}
export default translations
