// VocabularyShareDialog.tsx
import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { copyToClipboard } from '../Utilities/tools'

interface VocabularyShareDialogProps {
  open: boolean;
  onClose: () => void;
  vocabularyId: number;
}

const VocabularyShareDialog: React.FC<VocabularyShareDialogProps> = ({ open, onClose, vocabularyId }) => {

  const { t } = useTranslation()
  const handleCopyLink = () => {
    copyToClipboard(fullLink)
      .then(() => {
        toast.success(t('LinkCopiedToClipboard'))
      })
  }

  const fullLink = `https://${window.location.hostname}/?v=${vocabularyId}`

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('ShareVocabulary')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Vocabulary Link"
          type="text"
          fullWidth
          value={fullLink}
        />
        <Button onClick={handleCopyLink}>{t('CopyLink')}</Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('OK')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default VocabularyShareDialog